import React from 'react';
import { useBLoC } from 'hooks/useBLoC';
import { useInitBloc } from 'hooks/useInitBloc';
import { BLoCBase, BLoCParams, renderBlocChild, IBLoCInitialisable } from 'types/BLoCBase';
import { combineLatest, distinctUntilChanged, finalize, first, map, shareReplay, switchMap, tap } from 'rxjs';
import { CheckboxChangeEvent } from 'primereact/checkbox';
import { $get, $put } from 'services/api';
import { useObservableState } from 'observable-hooks';
import { getCustomerStore } from 'stores/customer.store';
import { getBillingPlanStore } from 'stores/billingPlan.store';
import { ImportModalCsvTypes } from 'components/modals/ImportModal/components/ImportModalCsv/ImportModalCsv.types';
import {
  TC_IMPORT_COUNT_LIMIT,
  TC_IMPORT_TEXT_RESPONSE_TYPE_ID,
  useImportModalCsvBLoC,
} from 'components/modals/ImportModal/components/ImportModalCsv/ImportModalCsv.bloc';
import { getUserStore } from 'stores/user.store';
import { getFFStore } from 'stores/ff.store';

type Column = ImportModalCsvTypes.IngestionColumn & {
  costData: { colName: string; hasHistoricTc: boolean; realCost: number };
};

type State = {
  selectedColumns: number[];
  loading?: boolean;
  columns: Column[];
  baseColumns: ImportModalCsvTypes.IngestionColumn[];
  useLegacyCodeframes?: boolean;
};

class BLoC extends BLoCBase<State> implements IBLoCInitialisable {
  public $loading = this.$getState('loading');
  public $useLegacyCodeframes = this.$getState('useLegacyCodeframes');
  public $columns = this.$getState('columns');
  public $baseColumns = this.$getState('baseColumns');
  public $selectedColumns = this.$getState('selectedColumns');
  public $ingestionCostsDetails = $get<ImportModalCsvTypes.CostDetails>(
    `ingestions/${this.currentIngestionId}/cost-details`
  ).pipe(shareReplay());

  public $hasTcPipelineSelection = combineLatest([
    getUserStore().$user,
    getFFStore().$hasFF('ff-tc-codeframes'),
  ]).pipe(
    map(([user, hasCodeframesFlagEnabled]) => (user && user.isYabbleOwner) || hasCodeframesFlagEnabled),
    distinctUntilChanged()
  );

  public $pipelines = $get<{ id: number; name: string; allowsCodeframe: boolean }[]>(
    'pipelines',
    { pipelineTypeId: 2 },
    { root: '/admin-api/' }
  ).pipe(shareReplay());

  public $codeFrames = $get<{ id: number; name: string }[]>(
    'theme-codeframes',
    {},
    { root: '/admin-api/' }
  ).pipe(shareReplay());

  public $codeFrameList = getCustomerStore()
    .$customerSlug.pipe(
      first((slug) => !!slug),
      switchMap((customerSlug) =>
        $get<{ id: number; name: string }[]>(`ingestions/customers/${customerSlug}/codeframes`, {}, {})
      )
    )
    .pipe(shareReplay());

  public $filteredColumn = this.$columns.pipe(
    map((columns) => columns.filter((col) => col.costData.realCost >= TC_IMPORT_COUNT_LIMIT))
  );

  public $isSelectedAll = combineLatest([this.$selectedColumns, this.$filteredColumn]).pipe(
    map(
      ([selectedColumns, columns]) => !!(selectedColumns?.length === columns.length && selectedColumns.length)
    )
  );

  public $selectColumnsData = combineLatest([this.$columns, this.$selectedColumns]).pipe(
    map(([columnsWithCostDetails, selectedColumns]) =>
      columnsWithCostDetails.filter((col) => selectedColumns.includes(col.id))
    )
  );

  public $comments = this.$selectColumnsData.pipe(
    map((cols) => cols.reduce((acc, item) => acc + (+item.costData.realCost || 0), 0))
  );

  public $credits = getBillingPlanStore().$creditsBalance;

  public $remaining = combineLatest([this.$comments, this.$credits]).pipe(
    map(([comments = 0, credits = 0]) => credits - comments)
  );

  public $notEnoughCredits = combineLatest([
    this.$comments,
    this.$credits,
    getCustomerStore()?.$billingStatus,
  ]).pipe(
    map(([comments = 0, credits = 0, billingStatus]) => billingStatus !== 'disabled' && comments > credits)
  );

  constructor(
    public readonly currentIngestionId: number,
    public readonly currentIngestionColumns: ImportModalCsvTypes.IngestionColumn[],
    public readonly currentSelectedColumnIds: number[],
    public readonly setIngestion: (ingestion: ImportModalCsvTypes.Ingestion) => void,
    public readonly setIngestionColumns: (columns: ImportModalCsvTypes.IngestionColumn[]) => void,
    public readonly setSelectedColumnIds: (columns: number[]) => void,
    public readonly changeStep: (step: ImportModalCsvTypes.IngestionSteps) => void,
    public readonly openCreditsModal: (project: ImportModalCsvTypes.ProjectProp) => void,
    public readonly isEmptyProject: boolean
  ) {
    super({ selectedColumns: [], columns: [], baseColumns: [] });
  }

  public onInit = () => {
    this.addSub(
      $get<ImportModalCsvTypes.CostDetails>(`ingestions/${this.currentIngestionId}/cost-details`).subscribe(
        (resp) => {
          const columns: ImportModalCsvTypes.IngestionColumn[] = [...this.currentIngestionColumns].map(
            (c) => {
              return {
                ...c,
                extraInfo: {
                  ...c.extraInfo,
                  ...(c.responseDataTypeId === TC_IMPORT_TEXT_RESPONSE_TYPE_ID && {
                    recommendedCodeframeId:
                      typeof c.extraInfo.recommendedCodeframeId === 'number'
                        ? c.extraInfo.recommendedCodeframeId
                        : null,
                    recommendedPipelineId:
                      typeof c.extraInfo.recommendedPipelineId === 'number'
                        ? c.extraInfo.recommendedPipelineId
                        : null,
                    tcCodeframeId:
                      typeof c.extraInfo.tcCodeframeId === 'number' ? c.extraInfo.tcCodeframeId : null,
                  }),
                },
              };
            }
          );

          const filteredColumns: Column[] = [...columns]
            .filter((col) => !col.ignoreColumn && col.responseDataTypeId === TC_IMPORT_TEXT_RESPONSE_TYPE_ID)
            .map((c) => {
              const data = resp.columns.find((cost) => cost.colId === c.id);
              const cost = data?.cost.hasHistoricTc ? data?.cost.ownRowsCost : data?.cost.totalCost || 0;
              return {
                ...c,
                costData: {
                  hasHistoricTc: data?.cost.hasHistoricTc || false,
                  realCost: cost,
                  colName: c.customName || data?.colName || '',
                },
              };
            });

          this.setStates({
            baseColumns: columns,
            columns: filteredColumns,
            selectedColumns: [
              ...this.currentSelectedColumnIds.filter((col) => {
                const column = filteredColumns.find((c) => c.id === col);
                return (column?.costData.realCost || 0) >= TC_IMPORT_COUNT_LIMIT;
              }),
            ],
          });
        }
      ),
      'columnWithcost'
    );
  };

  public back = () => {
    this.setSelectedColumnIds(this.currentState('selectedColumns'));
    this.changeStep('csv_received');
  };

  public commitChanges = (next: boolean, onSuccess?: () => void) => {
    this.setState('loading', true);
    this.addSub(
      this.$selectedColumns
        .pipe(
          map((selectedColumnIds) =>
            this.currentState('baseColumns').map((col) => ({
              ...col,
              tcEnabled: !!selectedColumnIds.includes(col.id),
            }))
          ),
          tap((columns) => this.setIngestionColumns(columns)),
          map((columns) =>
            columns.map(({ id, customName, ignoreColumn, tcEnabled, responseDataTypeId, extraInfo }) => ({
              id,
              customName,
              ignoreColumn,
              tcEnabled,
              responseDataTypeId,
              extraInfo,
            }))
          )
        )
        .pipe(
          switchMap((columns) =>
            combineLatest([
              $put<ImportModalCsvTypes.Ingestion>(`ingestions/${this.currentIngestionId}/set-tc`, {
                mapping: columns,
                goToNextStep: next,
              }).pipe(finalize(() => this.setState('loading', false))),
              this.$comments,
            ])
          )
        )
        .pipe(first())
        .subscribe(([ingestion, comments]) => {
          this.setIngestion(ingestion);
          this.setSelectedColumnIds(this.currentState('selectedColumns'));
          next &&
            this.openCreditsModal({
              amount: comments,
              id: ingestion?.id || '',
              name: ingestion?.name || '',
              type: 'ingestion',
            });
          onSuccess?.();
        }),
      'commitChanges'
    );
  };

  public handleUseLegacyCodeframes = (value?: boolean) => this.setState('useLegacyCodeframes', value);

  public handleChangeCheckbox = (e: CheckboxChangeEvent, id: number) =>
    e.checked ? this.selectColunm(id) : this.unselectColunm(id);

  public selectColunm = (id: number) =>
    this.mutateState('selectedColumns', (prevState) => [...prevState, id]);

  public unselectColunm = (id: number) => {
    this.mutateState('selectedColumns', (prevState) => [...prevState].filter((col) => col !== id));
    this.handleChangePipelineId(id, null);
  };

  public handleChangeMainCheckbox = (e: CheckboxChangeEvent) =>
    e.checked ? this.selectAll() : this.unselectAll();

  public selectAll = () => {
    this.addSub(
      this.$filteredColumn.pipe(first()).subscribe((columns) => {
        this.setState(
          'selectedColumns',
          columns.map((col) => col.id)
        );
        this.unsub('selectAll');
      }),
      'selectAll'
    );
  };

  public unselectAll = () => {
    this.setState('selectedColumns', []);
    this.addSub(
      this.$columns.pipe(first()).subscribe((columns) => {
        columns.forEach((col) => this.handleChangePipelineId(col.id, null));
        this.unsub('unselectAll');
      }),
      'unselectAll'
    );
  };

  public handleChangePipelineId = (id: number, value: number | null) => {
    this.mutateState('columns', (prevState) => {
      let tempColumns = prevState.slice();
      const i = tempColumns.findIndex((ing) => ing.id === id);
      tempColumns[i].extraInfo.recommendedPipelineId = value;
      tempColumns[i].extraInfo.recommendedCodeframeId = null;
      return tempColumns;
    });
  };

  public handleChangeCodeFrameId = (id: number, value: number | null) => {
    this.mutateState('columns', (prevState) => {
      let tempColumns = prevState.slice();
      const i = tempColumns.findIndex((ing) => ing.id === id);
      tempColumns[i].extraInfo.recommendedCodeframeId = value;
      return tempColumns;
    });
  };

  public handleChangeTcCodeframeId = (id: number, value: number | null) => {
    this.mutateState('columns', (prevState) => {
      let tempColumns = prevState.slice();
      const i = tempColumns.findIndex((ing) => ing.id === id);
      tempColumns[i].extraInfo.tcCodeframeId = value;
      return tempColumns;
    });
  };
}

const Context = React.createContext<Readonly<BLoC>>({} as any);

export const useHeyYabbleCountStepBLoC = () => useBLoC<BLoC>(Context);

export const HeyYabbleCountStepBLoC: React.FC<BLoCParams<BLoC, State>> = ({ children }) => {
  const {
    $currentIngestion,
    $currentIngestionColumns,
    $currentSelectedColumnIds,
    setIngestion,
    setIngestionColumns,
    changeStep,
    setSelectedColumnIds,
    openCreditsModal,
    isEmptyProject,
  } = useImportModalCsvBLoC();

  const ingestion = useObservableState($currentIngestion.pipe(first()));
  const columns = useObservableState($currentIngestionColumns.pipe(first()), []);
  const selectedColumnIds = useObservableState($currentSelectedColumnIds.pipe(first()), []);
  const selectedIds = selectedColumnIds?.length
    ? selectedColumnIds
    : columns
        .filter(
          (col) =>
            col.tcEnabled && !col.ignoreColumn && col.responseDataTypeId === TC_IMPORT_TEXT_RESPONSE_TYPE_ID
        )
        .map(({ id }) => id);

  const bloc = useInitBloc(
    () =>
      ingestion && columns
        ? new BLoC(
            ingestion.id,
            columns,
            selectedIds,
            setIngestion,
            setIngestionColumns,
            setSelectedColumnIds,
            changeStep,
            openCreditsModal,
            isEmptyProject || false
          )
        : null,
    [ingestion, columns, selectedColumnIds]
  );
  return bloc ? <Context.Provider value={bloc}>{renderBlocChild(children, bloc)}</Context.Provider> : null;
};
