import React, { useMemo, useState } from 'react';
import { NotificationStyle as S } from './Notification.style';
import { useClassNames } from 'hooks/useClassNames';
import { Icon } from 'components/Icon/Icon';
import { Box } from 'components/layouts/primitives/Box/Box';
import { PButtonIcon } from 'components/prime/PButton/PButtonIcon';
import { HStack, Stack } from 'components/layouts/primitives/Stack/Stack';
import { Text } from 'components/Text/Text';
import { PButton } from 'components/prime/PButton/PButton';
import { colourPalette } from 'styles/styles';
import { PButtonLink } from 'components/prime/PButton/PButtonLink';

type NotificationProps = {
  title?: string;
  description?: string;
  severity?: 'generic' | 'informative' | 'success' | 'warning' | 'error';
  layoutType?: 'spacious' | 'compact';
  hideIcon?: boolean;
  primaryActionLabel?: string;
  primaryActionOnClick?: () => void;
  secondaryActionLabel?: string;
  secondaryActionOnClick?: () => void;
  dismissable?: boolean;
  onDismiss?: () => void;
  className?: string;
};

const severityToIconMap: Record<NonNullable<NotificationProps['severity']>, React.ReactNode> = {
  generic: <Icon icon="announcement01Solid" size={20} color="violet500" />,
  informative: <Icon icon="infoCircleSolid" size={20} color="sky500" />,
  success: <Icon icon="checkCircleSolid" size={20} color="green500" />,
  warning: <Icon icon="alertTriangleSolid" size={20} color="amber500" />,
  error: <Icon icon="alertOctagonSolid" size={20} color="rose500" />,
};

const severityToColorsMap: Record<
  NonNullable<NotificationProps['severity']>,
  {
    bgColor: keyof typeof colourPalette;
    bgColorAlpha?: number;
    titleColor: keyof typeof colourPalette;
    descriptionColor: keyof typeof colourPalette;
  }
> = {
  generic: {
    bgColor: 'neutral50',
    titleColor: 'neutral900',
    descriptionColor: 'neutral500',
  },
  informative: {
    bgColor: 'sky500',
    titleColor: 'sky600',
    descriptionColor: 'sky600',
    bgColorAlpha: 0.05,
  },
  success: {
    bgColor: 'green500',
    titleColor: 'green600',
    descriptionColor: 'green600',
    bgColorAlpha: 0.05,
  },
  warning: {
    bgColor: 'amber600',
    titleColor: 'amber600',
    descriptionColor: 'amber600',
    bgColorAlpha: 0.05,
  },
  error: {
    bgColor: 'rose500',
    titleColor: 'rose600',
    descriptionColor: 'rose600',
    bgColorAlpha: 0.05,
  },
};

export const Notification: React.FC<NotificationProps> = ({
  title,
  description,
  severity,
  layoutType,
  primaryActionLabel,
  primaryActionOnClick,
  secondaryActionLabel,
  secondaryActionOnClick,
  hideIcon,
  dismissable,
  onDismiss,
  className,
}) => {
  const [isVisible, setIsVisible] = useState(true);
  const notificationColors = useMemo(() => severityToColorsMap[severity || 'generic'], [severity]);
  const classNames = useClassNames(
    {
      [`notification-severity-${severity || 'generic'}`]: true,
      [`notification-layout-type-${layoutType || 'spacious'}`]: true,
    },
    className
  );

  return isVisible ? (
    <S.Container
      padding="paddingMD"
      backgroundColor={notificationColors.bgColor}
      backgroundColorAlpha={notificationColors.bgColorAlpha}
      className={classNames}
    >
      <HStack gap="gap2" align={layoutType === 'compact' ? 'center' : 'flex-start'}>
        {!hideIcon && (
          <Box fit="content" className="notification-icon">
            {severityToIconMap[severity || 'generic']}
          </Box>
        )}
        <Box padding={['paddingNone', 'paddingSM']} fit="space">
          <Stack
            direction={layoutType === 'compact' ? 'row' : 'column'}
            gap="gap4"
            align={layoutType === 'compact' ? 'center' : 'flex-start'}
            splitAfterIdx={0}
          >
            <Stack
              direction={layoutType === 'compact' ? 'row' : 'column'}
              gap={layoutType === 'compact' ? 'gap4' : 'gapNone'}
            >
              {!!title && (
                <Text
                  size="fontSizeSM"
                  weight="medium"
                  lineHeight="lineHeightMD"
                  color={notificationColors.titleColor}
                >
                  {title}
                </Text>
              )}
              {!!description && (
                <Text size="fontSizeSM" lineHeight="lineHeightMD" color={notificationColors.descriptionColor}>
                  {description}
                </Text>
              )}
            </Stack>
            {(!!primaryActionLabel || !!secondaryActionLabel) && (
              <HStack gap="gap4" align="center">
                {!!primaryActionLabel && (
                  <PButton
                    label={primaryActionLabel}
                    onClick={primaryActionOnClick}
                    severity="tertiary"
                    size="sm"
                  />
                )}
                {!!secondaryActionLabel && (
                  <PButtonLink
                    label={secondaryActionLabel}
                    onClick={secondaryActionOnClick}
                    severity="secondary"
                  />
                )}
              </HStack>
            )}
          </Stack>
        </Box>
        {!!dismissable && (
          <Box fit="content">
            <PButtonIcon
              icon="xLine"
              severity="primary"
              size="xs"
              shape="squircle"
              onClick={() => {
                setIsVisible(false);
                onDismiss?.();
              }}
            />
          </Box>
        )}
      </HStack>
    </S.Container>
  ) : null;
};
