import React from 'react';
import { ImportModalCsvTypes } from 'components/modals/ImportModal/components/ImportModalCsv/ImportModalCsv.types';
import { useMapStepBLoC } from '../../../../../../MapStep.bloc';
import { ColumnName } from './components/ColumnName/ColumnName';
import { ColumnType } from './components/ColumnType/ColumnType';
import { MapStepColumnHeaderStyle as S } from './MapStepColumnHeader.style';
import { PInputSwitch } from 'components/prime/PInputSwitch/PInputSwitch';

export const MapStepColumnHeader: React.FC<{ column: ImportModalCsvTypes.IngestionColumn }> = ({
  column,
}) => {
  const { handleChangeIgnoreColumn } = useMapStepBLoC();
  return (
    <S.Container>
      <S.FirstSection>
        <PInputSwitch
          checked={!column.ignoreColumn}
          onChange={(e) => handleChangeIgnoreColumn(column.id, !!e.target.value)}
          data-testid="checkbox-map-step-column"
        />
        <ColumnType {...{ column }} />
      </S.FirstSection>
      <ColumnName {...{ column }} />
    </S.Container>
  );
};
