import { Column } from 'primereact/column';
import styled from 'styled-components';
import { colourPalette } from 'styles/styles';
/**
 * @deprecated Use PDataTableV2 instead
 */
export const PColumnStyle = {
  Column: styled(Column)``,
  Header: styled.div`
    display: flex;
    align-items: center;
    user-select: none;
  `,
  Sorter: styled.div<{ $sortedReverse?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    cursor: pointer;
    margin-left: 8px;
    border-radius: 50%;
    ${({ $sortedReverse }) => $sortedReverse && 'transform: rotate(180deg);'}
    &:hover {
      background-color: ${colourPalette.neutral300};
    }
  `,
};
