import React from 'react';
import { FileUploadItemStyle as S } from './FileUploadItem.style';
import { LinkText } from 'components/LinkText/LinkText';
import { HStack } from 'components/layouts/primitives/Stack/Stack';
import { Icon } from 'components/Icon/Icon';

export const FileUploadItem: React.FC<{
  file: File;
  openFn: () => void;
  fileIsDuplicate?: boolean;
}> = ({ file, openFn, fileIsDuplicate }) => {
  return file ? (
    <S.Container>
      <S.Icon />
      <HStack gap="gap2" align="center">
        {fileIsDuplicate && <Icon icon="alertTriangleSolid" color="amber500" size={24} className="block" />}
        <S.Text>{file.name}</S.Text>
      </HStack>
      {fileIsDuplicate && (
        <S.Subtext>
          The content of this file has been detected in a previous upload.
          <br />
          Proceeding could result in inaccurate insights.
        </S.Subtext>
      )}
      <LinkText onClick={() => openFn()}>Change</LinkText>
    </S.Container>
  ) : null;
};
