import React, { ReactNode, useEffect, useMemo } from 'react';
import { useIsReactPage } from 'hooks/useIsReactPage';
import { useObservableState } from 'observable-hooks';
import { classNames } from 'primereact/utils';
import { getBillingPlanStore } from 'stores/billingPlan.store';
import { getCustomerStore } from 'stores/customer.store';
import { getFFStore } from 'stores/ff.store';
import { LeftMenuBurger } from './components/LeftMenuBurger/LeftMenuBurger';
import { LeftMenuLogo } from './components/LeftMenuLogo/LeftMenuLogo';
import { LeftMenuWidgetStyle as S } from './LeftMenuWidget.style';
import { useClassNames } from 'hooks/useClassNames';
import { getUserStore } from 'stores/user.store';
import { User } from 'types/User';

const getLinks = (
  slug: string | null,
  myPanelEnabled: boolean | null,
  userRole?: Extract<User['role'], 'administrator' | 'user'>
) => {
  const links: {
    href?: string;
    to?: string;
    label: string;
    active?: true;
    disabled?: boolean | null;
    tag?: ReactNode;
    exact?: boolean;
    hidden?: boolean;
  }[] = [
    { to: `/home`, label: 'Home', exact: true },
    { to: `/projects`, label: 'Projects' },
    { to: `/hey-yabble`, label: 'Hey Yabble' },
    {
      to: `/stories`,
      label: 'Stories',
      hidden: !getFFStore().hasFF('ff-stories'),
    },
    ...(userRole === 'administrator'
      ? [{ href: `/${slug || 'default'}/panels`, label: 'MyPanel', disabled: !myPanelEnabled || false }]
      : []),
  ];
  return slug
    ? links.map((link) => {
        return Object.assign({}, link, {
          active: link.exact
            ? window.location.pathname === (link.href || link.to)
            : (link.href || link.to) && window.location.pathname.startsWith(link.href || link.to!),
        });
      })
    : links;
};

export const LeftMenuWidget: React.FC<{ lightTheme?: boolean; customMenu?: ReactNode }> = ({
  lightTheme,
  customMenu,
}) => {
  const userRole = useObservableState(getUserStore().$userRole);
  const customerSlug = useObservableState(getCustomerStore().$customerSlug, '');
  const myPanelEnabled = useObservableState(getBillingPlanStore().$myPanelEnabled, false);
  const adjustedLinks = useMemo(
    () => getLinks(customerSlug, myPanelEnabled, userRole),
    [customerSlug, myPanelEnabled, userRole]
  );
  useEffect(() => {
    const sideMenuHidden = localStorage.getItem('side-menu-hidden');
    if (sideMenuHidden === 'true') {
      document.body.classList.add('side-menu-hidden');
    } else {
      document.body.classList.remove('side-menu-hidden');
    }
  }, []);
  const containerClassNames = useClassNames({
    'left-menu-light-theme': lightTheme,
  });
  const isInFullReactMode = useIsReactPage();

  return (
    <S.Container className={containerClassNames}>
      <S.LogoWrapper>
        <LeftMenuLogo />
        <LeftMenuBurger />
      </S.LogoWrapper>
      <S.Menu>
        {customMenu ||
          adjustedLinks
            .filter((a) => !a.hidden)
            .map((a) =>
              a.to && isInFullReactMode ? (
                <S.ReactLink
                  key={a.to || '' + a.label}
                  to={a.to || ''}
                  className={classNames({ active: a.active, disabled: a.disabled })}
                  data-testid="button-side-bar-menu"
                >
                  {a.label} {a.tag}
                </S.ReactLink>
              ) : (
                <S.ALink
                  key={a.href || '' + a.label}
                  href={a.href || a.to}
                  className={classNames({ active: a.active, disabled: a.disabled })}
                >
                  {a.label} {a.tag}
                </S.ALink>
              )
            )}
      </S.Menu>
    </S.Container>
  );
};
