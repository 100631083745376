import React, { useState } from 'react';
import { FullWidthTipStyle as S } from './FullWidthTip.style';
import { ReactComponent as BulbTip } from 'assets/svg/modals/import-modal/bulb-tip.svg';
import { LinkText } from 'components/LinkText/LinkText';
import { differenceInHours } from 'date-fns';
import { Box } from 'components/layouts/primitives/Box/Box';
import { Icon } from 'components/Icon/Icon';

const LOCAL_STORAGE_ITEM_IDENTIFIER = 'yabble-import-csv-label-file-tip-dismiss-date';

export const FullWidthTip: React.FC = () => {
  const [dismissed, setDismissed] = useState(() => {
    const dismissDate = JSON.parse(localStorage.getItem(LOCAL_STORAGE_ITEM_IDENTIFIER) || 'null');
    return (
      !!dismissDate && differenceInHours(new Date(), new Date(dismissDate), { roundingMethod: 'floor' }) < 24
    );
  });

  return !dismissed ? (
    <S.Container>
      <header>
        <BulbTip />
        <p>How to label your file</p>
        <Box
          className="close"
          onClick={() => {
            localStorage.setItem(LOCAL_STORAGE_ITEM_IDENTIFIER, JSON.stringify(new Date()));
            setDismissed(true);
          }}
        >
          <Icon icon="xCloseLine" size={20} className="block" />
        </Box>
      </header>
      <section>
        <p>
          Ensure column titles are descriptive to guide the AI on how to count your data. For example, “How
          would you describe a restaurant that was good value for money?” as opposed to just “Good value
          restaurants”.{' '}
          <LinkText
            href="https://intercom.help/yabblezone/en/articles/9493678-1-prepare-your-data-for-import-to-count"
            target="_blank"
            rel="noopener noreferrer"
          >
            Follow our guidelines
          </LinkText>{' '}
          to achieve the most accurate results.
        </p>
      </section>
    </S.Container>
  ) : null;
};
