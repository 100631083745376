import styled from 'styled-components';
import { colourPalette } from 'styles/styles';

export const ListPanelFiltersStyle = {
  Container: styled.div`
    min-height: 100%;
    display: flex;
    flex-direction: column;
  `,
  Title: styled.p`
    margin: 0;
    padding: 0 8px 14px;
    flex: 0 0 fit-content;
    font-size: 12px;
    line-height: 24px;
    font-weight: 500;
    color: ${colourPalette.neutral500};
    text-transform: uppercase;
  `,
};
