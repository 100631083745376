import { PDialog } from 'components/prime/PDialog/PDialog';
import styled from 'styled-components';
import { colourPalette } from 'styles/styles';
export const MapStepDataTypesModalStyle = {
  Dialog: styled(PDialog)`
    width: 700px !important;
    .p-dialog-header {
      padding: 16px 24px !important;
      border-bottom: 1px solid ${colourPalette.neutral300};
    }
  `,
};
