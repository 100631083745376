import React, { useMemo } from 'react';
import { useObservableState } from 'observable-hooks';
import { HeyYabbleCountStepTableRowCodeFramesDropdownStyle as S } from './HeyYabbleCountStepTableRowCodeFramesDropdown.style';
import { useHeyYabbleCountStepBLoC } from '../../../../HeyYabbleCountStep.bloc';
import { PTooltip } from 'components/prime/PTooltip/PTooltip';
import { Text } from 'components/Text/Text';

export const HeyYabbleCountStepTableRowCodeFramesDropdown: React.FC<{
  idCol: number;
  tcCodeframeId?: number | null;
  isChecked: boolean;
}> = ({ isChecked, idCol, tcCodeframeId }) => {
  const { $codeFrameList, handleChangeTcCodeframeId } = useHeyYabbleCountStepBLoC();
  const codeFrames = useObservableState($codeFrameList, []);

  const isDisabled = useMemo(() => {
    return !isChecked;
  }, [isChecked]);

  return (
    <S.Dropdown
      value={tcCodeframeId}
      optionValue="id"
      optionLabel="name"
      options={codeFrames}
      placeholder="Select a codeframe"
      onChange={(e) => handleChangeTcCodeframeId(idCol, e.target.value)}
      disabled={isDisabled}
      containerClassName="w-full"
      itemTemplate={(d) => (
        <Text fit="space" ellipsis className={`codeframe-list-item-${d?.id}`}>
          {d?.name}
          <PTooltip target={`.codeframe-list-item-${d?.id}`} position="right" content={d?.name} />
        </Text>
      )}
    />
  );
};
