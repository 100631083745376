import React from 'react';
import { UserIconStyle as S } from './UserIcon.style';
import { colourPalette } from 'styles/styles';

export const UserIcon: React.FC<{
  user?: { firstName: string; lastName: string } | null;
  disabled?: boolean;
}> = ({ user, disabled }) => {
  return (
    <S.Container $colour={disabled ? colourPalette.neutral400 : colourPalette.green500}>
      <S.Text>
        {user?.firstName?.length && user.firstName[0]}
        {user?.lastName?.length && user.lastName[0]}
      </S.Text>
    </S.Container>
  );
};
