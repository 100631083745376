import styled from 'styled-components';
import { ReactComponent as YabbleLogoSvg } from 'assets/svg/yabble/yabble-logo-full.svg';
import { colourPalette, hexToRGBA } from 'styles/styles';

export const YabbleLogoStyle = {
  Logo: styled(YabbleLogoSvg)<{ $props: any }>`
    height: ${({ $props }) =>
      $props.height ? ($props.height === 'full' ? '100%' : `${$props.height}px`) : 'fit-content'};
    width: ${({ $props }) =>
      $props.width ? ($props.width === 'full' ? '100%' : `${$props.width}px`) : 'fit-content'};
    fill: ${({ $props }) =>
      $props.color
        ? typeof $props.colorAlpha === 'number'
          ? hexToRGBA((colourPalette as any)[$props.color], $props.colorAlpha)
          : (colourPalette as any)[$props.color]
        : colourPalette.neutral900};
    vertical-align: bottom;
  `,
};
