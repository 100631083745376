import styled from 'styled-components';
import { colourPalette } from 'styles/styles';

export const TopBarBannerStyle = {
  Container: styled.div`
    position: fixed;
    width: 100vw;
    height: 56px;
    z-index: 1001;
    display: flex;
    align-items: center;
    background-color: ${colourPalette.neutral100};
    padding: 0px 28px;
    left: 0px;
    top: 0px;
    & > svg {
      path {
        fill: ${colourPalette.rose600};
      }
    }
  `,
  Message: styled.div`
    color: ${colourPalette.neutral900};
    & > a {
      text-decoration: underline;
      color: ${colourPalette.neutral900};
    }
  `,
};
