import React from 'react';
import { MapStepDataTypeDataStyle as S } from './MapStepDataTypeData.style';
import { useMapStepBLoC } from '../../MapStep.bloc';
import { LinkText } from 'components/LinkText/LinkText';

export const MapStepDataTypeData: React.FC<{ type?: 'popover' | 'modal' }> = ({ type = 'popover' }) => {
  const { selectIcon } = useMapStepBLoC();

  return (
    <S.Container>
      <S.Item {...{ type }}>
        <S.TitleSection>
          {selectIcon(1)}
          <S.Subtitle>Custom variable</S.Subtitle>
        </S.TitleSection>
        <S.ContentSection {...{ type }}>
          <S.Text>
            These are additional pieces of information to assist with analysis. You can use custom variables
            to filter your data. Common examples include demographics, attitudes and ratings. Add as many as
            you like - these should be included at this import stage and cannot be added later.
          </S.Text>
          <S.SecondBox>
            <span>e.g. Age, Location, Regular Shopper.</span>
          </S.SecondBox>
        </S.ContentSection>
      </S.Item>
      <S.Item {...{ type }}>
        <S.TitleSection>
          {selectIcon(2)}
          <S.Subtitle>Text</S.Subtitle>
        </S.TitleSection>
        <S.ContentSection {...{ type }}>
          <S.Text>
            Select this for any unstructured data that you want to count or query. These are generally
            comments from surveys or product/experience reviews.
          </S.Text>
          <S.SecondBox>
            <span>e.g. What was the reason for your rating? or How could we improve your experience?</span>
          </S.SecondBox>
        </S.ContentSection>
      </S.Item>
      <S.Item {...{ type }}>
        <S.TitleSection>
          {selectIcon(4)}
          <S.Subtitle>NPS (Net Promoter Score)</S.Subtitle>
        </S.TitleSection>
        <S.ContentSection {...{ type }}>
          <S.Text>
            If you've collected data about likelihood to recommend on a scale of 0-10, use this data type to
            automatically display an NPS score in your explore (count).{' '}
            <LinkText
              href="https://intercom.help/yabblezone/en/articles/5655302-question-types-using-the-right-one-to-meet-your-research-needs#h_2880c939d4:~:text=of%20your%20brand.-,Calculating%20NPS,-NPS%20is%20rated"
              target="_blank"
            >
              Learn more about how NPS is calculated
            </LinkText>
          </S.Text>
        </S.ContentSection>
      </S.Item>
      <S.Item {...{ type }}>
        <S.TitleSection>
          {selectIcon(3)}
          <S.Subtitle>Response date</S.Subtitle>
        </S.TitleSection>
        <S.ContentSection {...{ type }}>
          <S.Text>
            You must include a response date in your data file. This should be (
            <S.TextBold>dd-mm-yyyy </S.TextBold> or <S.TextBold>mm-dd-yyyy</S.TextBold>). You have the option
            to change to US format.
          </S.Text>
        </S.ContentSection>
      </S.Item>
    </S.Container>
  );
};
