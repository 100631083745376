import styled from 'styled-components';
import { ReactComponent as FileSvg } from 'assets/svg/modals/import-modal/file.svg';
import { colourPalette } from 'styles/styles';
export const FileUploadItemStyle = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `,
  Icon: styled(FileSvg)``,
  Text: styled.p`
    font-size: 20px;
    line-height: 24px;
    color: ${colourPalette.neutral900};
    font-weight: 600;
    text-align: center;
  `,
  Subtext: styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: ${colourPalette.neutral500};
    text-align: center;
    margin-top: -4px;
    margin-bottom: 4px;
  `,
};
