import React, { useMemo } from 'react';
import { useMapStepBLoC } from 'components/modals/ImportModal/components/ImportModalCsv/components/ImportModalCsvContent/components/MapStep/MapStep.bloc';
import { ImportModalCsvTypes } from 'components/modals/ImportModal/components/ImportModalCsv/ImportModalCsv.types';
import { HStack } from 'components/layouts/primitives/Stack/Stack';
import { Text } from 'components/Text/Text';

export const ColumnTypeDropdownItemData: React.FC<{
  column: ImportModalCsvTypes.IngestionColumn;
  name: string;
}> = ({ column, name }) => {
  const { selectIcon } = useMapStepBLoC();
  const icon = useMemo(() => {
    return selectIcon(column.id);
  }, [column, selectIcon]);

  return (
    <HStack gap="gap3" align="center">
      <HStack fit="content" align="center" justify="center">
        {icon}
      </HStack>
      <Text size="fontSizeSM">{name}</Text>
    </HStack>
  );
};
