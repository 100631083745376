import styled from 'styled-components';
import { Tooltip } from 'primereact/tooltip';
import { colourPalette } from 'styles/styles';
import TooltipArrowSvg from 'assets/svg/components/tooltip/tooltip-arrow.svg';

/**
 * @deprecated Use Tooltip instead
 */
export const PTooltipStyle = {
  Tooltip: styled(Tooltip)`
    .p-tooltip-arrow {
      all: unset;
      display: block;
      width: 12px;
      height: 7px;
      position: absolute;
      background-color: ${colourPalette.neutral900};
      mask-image: url(${TooltipArrowSvg});
      mask-repeat: no-repeat;
      mask-size: 100% auto;
    }

    .p-tooltip-text {
      padding: 12px;
      background: ${colourPalette.neutral900};
      color: ${colourPalette.neutral0};
      font-size: 12px;
    }

    &.p-tooltip-bottom {
      padding: 6px 0;

      .p-tooltip-arrow {
        top: 0px;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &.p-tooltip-top {
      padding: 6px 0;

      .p-tooltip-arrow {
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%) rotate(180deg);
      }
    }

    &.p-tooltip-left {
      padding: 0 10px;

      .p-tooltip-arrow {
        top: 50%;
        right: 1px;
        transform: translateY(-50%) rotate(90deg);
      }
    }

    &.p-tooltip-right {
      padding: 0 10px;

      .p-tooltip-arrow {
        top: 50%;
        left: 1px;
        transform: translateY(-50%) rotate(-90deg);
      }
    }
  `,
  TooltipContent: styled.div<{ $maxWidth: number }>`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 8px;
    max-width: ${({ $maxWidth }) => `${$maxWidth}px`};

    .tooltip-custom-title {
      font-size: 12px;
      font-weight: 500;
      color: ${colourPalette.neutral0};
      line-height: 16px;
    }

    .tooltip-custom-text {
      font-size: 12px;
      color: ${colourPalette.neutral0};
      line-height: 20px;
    }

    .tooltip-custom-actions {
      padding-top: 16px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 8px;
    }
  `,
};
