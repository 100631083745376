import styled from 'styled-components';
import { colourPalette } from 'styles/styles';

export const DetailsPanelContentCopyToActivityStyle = {
  Container: styled.div``,
  Message: styled.div`
    border: 1px solid ${colourPalette.neutral300};
    background-color: ${colourPalette.neutral100};
    padding: 16px;
    margin-bottom: 12px;
    color: ${colourPalette.neutral500};
    line-height: 21px;
    font-size: 13px;
  `,
};
