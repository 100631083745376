export const downloadFile = (blob: Blob, filename: string) => {
  const blobUrl = URL.createObjectURL(blob);
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.href = blobUrl;
  a.download = filename;
  a.click();
  a.parentNode?.removeChild(a);
  URL.revokeObjectURL(blobUrl);
};
