import { Dialog } from 'primereact/dialog';
import styled from 'styled-components';
import { colourPalette } from 'styles/styles';
/**
 * @deprecated Use PDialogV2 instead
 */
export const PDialogStyle = {
  Dialog: styled(Dialog)`
    border-radius: 12px;
    .p-dialog-header {
      @media print {
        display: none !important;
      }
      border-top-right-radius: 12px;
      border-top-left-radius: 12px;
      min-height: 56px;
      padding: 16px 24px;
      border-bottom: 1px solid ${colourPalette.neutral300};
      & > .p-dialog-title {
        font-weight: 500;
        font-size: 16px;
      }
      h3 {
        font-weight: 500;
      }
      .p-dialog-header-icons {
        align-self: center;
        .p-dialog-header-close {
          border: 1px solid ${colourPalette.neutral300};
          border-radius: 4px;
          width: 24px;
          height: 24px;
          background-color: transparent;
          font-size: 13px;
          &:hover {
            background: ${colourPalette.neutral200};
          }
          &:active {
            background: ${colourPalette.neutral300};
          }
        }
      }
    }
    .p-dialog-content {
      padding: 24px;
      border-radius: 0px;
    }
    &.no-padding .p-dialog-content {
      padding: 0px;
    }
    &.no-footer .p-dialog-content {
      border-bottom-right-radius: 12px;
      border-bottom-left-radius: 12px;
    }
    &.no-header .p-dialog-content {
      border-top-right-radius: 12px;
      border-top-left-radius: 12px;
      padding: 0px 24px 24px 24px;
    }
    .p-dialog-footer {
      border-top: 1px solid ${colourPalette.neutral300};
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      padding: 24px 40px;
      border-bottom-right-radius: 12px;
      border-bottom-left-radius: 12px;
      & > button.p-dialog-footer {
        margin: 0 0 0 0.5rem;
      }
      & > button.p-button:last-child {
        margin-right: 0px;
      }
    }
  `,
  DialogHeader: styled.div<{ type?: 'default' | 'centered' }>`
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: ${({ type }) => (type === 'centered' ? 'center' : 'space-between')};
    .p-dialog-header-icons {
      margin-top: -8px;
      position: absolute;
      top: 16px;
      right: 16px;
      z-index: 1001;
    }
  `,
  DialogTitle: styled.div`
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    padding-top: 16px;
    width: 100%;
  `,
};
