import { SelectButton } from 'primereact/selectbutton';
import styled from 'styled-components';
import { borderRadius, colourPalette, padding } from 'styles/styles';

export const PSelectButtonV2Style = {
  SelectButton: styled(SelectButton)`
    background: ${colourPalette.neutral100};
    width: fit-content;
    border-radius: ${borderRadius.roundedMD};
    padding: ${padding.paddingXXS};
    .p-button {
      height: 32px;
      min-width: 40px !important;
      border-radius: ${borderRadius.roundedSM} !important;
      background: ${colourPalette.neutral100} !important;
      border: none;
      padding: 0 ${padding.paddingSM};
      .p-button-label,
      .p-button-icon {
        color: ${colourPalette.neutral500};
      }
      &.p-highlight {
        background: ${colourPalette.neutral0} !important;
        box-shadow: 0px 1px 2px -1px rgba(0, 0, 0, 0.1), 0px 1px 3px 0px rgba(0, 0, 0, 0.1) !important;
        .p-button-label,
        .p-button-icon {
          color: ${colourPalette.neutral900};
        }
      }
      &:hover {
        background: ${colourPalette.neutral100} !important;
        .p-button-label,
        .p-button-icon {
          color: ${colourPalette.neutral600};
        }
        &.p-highlight {
          .p-button-label,
          .p-button-icon {
            color: ${colourPalette.neutral900};
          }
        }
      }
      &.p-disabled {
        background: ${colourPalette.neutral100} !important;
        .p-button-label,
        .p-button-icon {
          color: ${colourPalette.neutral300};
        }
      }
      &:focus,
      &:focus-visible,
      &:active {
        box-shadow: none;
      }
    }
    &.full-width {
      width: 100%;
      display: flex;
      .p-button {
        flex: 1;
      }
    }
  `,
};
