import { createGlobalStyle } from 'styled-components';
import {
  borderRadius,
  borders,
  colourPalette,
  hexToRGBA,
  padding,
  fontSize,
  lineHeight,
  fontWeight,
} from 'styles/styles';
import { PDropdownGlobalStyle } from 'components/prime/PDropdown/PDropdown.style';
import { PCalendarGlobalStyle } from 'components/prime/PCalendar/PCalendar.style';
import { PMultiSelectGlobalStyle } from 'components/prime/PMultiSelect/PMultiSelect.style';
import { PAutoCompleteV2GlobalStyle } from 'components/prime/PAutoCompleteV2/PAutoCompleteV2.style';

export const BaseStyle = createGlobalStyle`
body {
  margin: 0px;
  font-family: 'SuisseIntl', sans-serif;
  font-size: ${fontSize.fontSizeSM};
  line-height: ${lineHeight.lineHeightMD};
  font-weight: ${fontWeight.regular};
  color: ${colourPalette.neutral900};

  &.intercom-hidden > .intercom-lightweight-app {
    display: none;
  }
}

.p-component {
  font-family: 'SuisseIntl', sans-serif;
  font-size: ${fontSize.fontSizeSM};
  font-weight: ${fontWeight.regular};
  line-height: ${lineHeight.lineHeightMD};
  color: ${colourPalette.neutral900};
}

::selection {
  background: #B4D5FE;
}

body a {
  cursor: pointer;
  text-decoration: none;
  color: ${colourPalette.blue600};
  &.disabled{
    cursor: unset;
    pointer-events: none;
  }
  &:focus:not(:hover) {
    text-decoration: none;
  }
  &:active:not(:hover) {
    text-decoration: none;
  }
}

b {
  font-weight: 600;
}

#app a,
a.link,
.p-dialog a {
  &:hover {
    text-decoration: none;
  }
  &.link:hover {
    text-decoration: underline;
  }
}

h1 {
  color: ${colourPalette.neutral900};
  font-size: 36px;
  font-weight: 600;
}
h2 {
  color: ${colourPalette.neutral900};
  font-size: 32px;
  font-weight: 600;
}

h3 {
  font-weight: 700;
  font-size: 28px;
  color: ${colourPalette.neutral900};
}

h4 {
  font-size: 20px;
  font-weight: 500;
  color: ${colourPalette.neutral900};
}
h5 {
  font-size: 18px;
  line-height: 26px;
  margin: 0px;
  font-weight: 500;
  color: ${colourPalette.neutral900};
}
h6 {
  font-size: 16px;
  line-height: 24px;
  margin: 0px;
  font-weight: 500;
  color: ${colourPalette.neutral900};
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

/// Prime overrides. things that cannot be placed in styled
.p-datepicker {
  .p-datepicker-month {
    width: 130px;
    height: 40px;
    font-size: ${fontSize.fontSizeSM};
    line-height: ${lineHeight.lineHeightMD};
    border-color: ${colourPalette.neutral200};
    background-color: ${colourPalette.neutral100};
    border-radius: ${borderRadius.roundedMD};
    padding: ${padding.paddingNone} ${padding.paddingMD};
  }
  .p-datepicker-year {
    width: 80px;
    height: 40px;
    font-size: ${fontSize.fontSizeSM};
    line-height: ${lineHeight.lineHeightMD};
    border-color: ${colourPalette.neutral200};
    background-color: ${colourPalette.neutral100};
    border-radius: ${borderRadius.roundedMD};
    padding: ${padding.paddingNone} ${padding.paddingMD};
  }

  .p-datepicker-calendar {
    .p-highlight {
      background-color: rgb(230, 237, 253);
      .selected {
        background-color: ${colourPalette.blue500};
        border-radius: ${borderRadius.roundedFull};
        width: 30px;
        height: 30px;
        color: ${colourPalette.neutral0};
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .p-datepicker-buttonbar {
    border: 0px;
    & > .p-button-text {
      background-color: ${colourPalette.blue500};
      color: #fff;
      border-radius: ${borderRadius.roundedFull};
      width: 100px;
      margin: 0px 8px;
    }
  }
}

.p-dialog-mask {
  background-color: ${hexToRGBA(colourPalette.neutral900, 0.2)} !important;
}

// scroll bar
body {
   overflow: overlay;
}
* {
  scrollbar-color: transparent transparent;
  &::-webkit-scrollbar {
    width: 14px;
  }


  &::-webkit-scrollbar-thumb {
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    min-height: 50px;
    z-index: -1;
  }
  :root:hover > &,
  &:hover{
    scrollbar-color: ${colourPalette.slate300} transparent;
    &::-webkit-scrollbar-thumb {
      background-color: ${colourPalette.slate300};
      border: 4px solid rgba(0, 0, 0, 0);
      width: 5px;
    } 
  }

  
  .suspense-component {
    opacity: 1;
    /* transition: opacity 1s; */
  }

  .scrollable {
    mask-image: linear-gradient(to top, transparent, black), linear-gradient(to left, transparent 17px, black 17px);
    mask-size: 100% 20000px;
    mask-position: left bottom;
    transition: mask-position 0.05s;
    overflow: auto;
    &:hover {
      -webkit-mask-position: left top;
    }
  }
}

// Stripe Elements overrides
.StripeElement {
  border-radius: ${borderRadius.roundedSM};
  border: ${borders.borderSM} solid ${colourPalette.gray200};
  background-color: ${colourPalette.neutral0};
  height: 40px;
  padding: 0px ${padding.paddingMD};
  display: flex;
  align-items: center;
  justify-content: stretch;
  margin-top: 4px;
  margin-bottom: 18px;
  transition: 0.2s;
  &:hover {
    border-color: ${colourPalette.gray300};
    background-color: ${colourPalette.neutral100};
  }
  &.StripeElement--focus {
    border-color: ${colourPalette.blue600};
    box-shadow: 0px 0px 0px 4px ${hexToRGBA(colourPalette.blue600, 0.3)};
    border-color: ${colourPalette.blue500};
    background-color: ${colourPalette.neutral0};
    color: ${colourPalette.neutral600};
  }
  &.StripeElement--invalid:not(.StripeElement--focus) {
    border-color: ${colourPalette.rose500};
    color: ${colourPalette.rose600};
  }
  & > div {
    flex: 1;
  }
}

.intercom-lightweight-app,
.intercom-launcher-frame {
  z-index: 2000 !important;
}

body.intercom-force-zindex {
.intercom-lightweight-app,
.intercom-launcher-frame {
    z-index: 99999 !important;
  }
}

// PComponents Global Styles
${PDropdownGlobalStyle}
${PMultiSelectGlobalStyle}
${PAutoCompleteV2GlobalStyle}
${PCalendarGlobalStyle}
`;
