import styled from 'styled-components';
import { AutoComplete } from 'primereact/autocomplete';
import {
  borderRadius,
  borders,
  colourPalette,
  fontWeight,
  fontSize,
  hexToRGBA,
  lineHeight,
  overridePrimeReactCssLayerDefinition,
} from 'styles/styles';
import { PDropdownContainerBaseStyle } from '../PDropdown/PDropdown.style';

/**
 * @deprecated Use PAutoCompleteV2 instead
 */
export const PAutoCompleteStyle = {
  Container: styled.div`
    ${PDropdownContainerBaseStyle}
  `,
  AutoComplete: styled(AutoComplete)<{ invalid?: boolean }>`
    &.p-autocomplete {
      width: 100%;
      height: 40px;
      position: relative;

      & > .p-inputtext {
        width: 100%;
        border-radius: ${borderRadius.roundedSM};
        background-color: ${colourPalette.neutral0};
        border: ${borders.borderSM} solid ${colourPalette.gray200};
        padding: 0 46px 0 12px;
        height: 40px;
        font-size: ${fontSize.fontSizeSM};
        font-weight: ${fontWeight.medium};
        line-height: ${lineHeight.lineHeightMD};

        &:hover {
          border-color: ${colourPalette.gray300};
          background-color: ${colourPalette.neutral100};
        }

        &:focus,
        &:focus-within,
        &:active {
          border-color: ${colourPalette.blue500};
          box-shadow: 0px 0px 0px 4px ${hexToRGBA(colourPalette.blue600, 0.3)};
          background-color: ${colourPalette.neutral0};
        }

        &::placeholder {
          color: ${colourPalette.neutral400};
          font-weight: ${fontWeight.medium};
        }

        &:disabled {
          background-color: ${colourPalette.neutral50};
          border-color: ${colourPalette.gray200};
          opacity: 1;
          color: ${colourPalette.neutral300};
          &::placeholder {
            color: ${colourPalette.neutral300};
          }
        }
      }

      & > .p-autocomplete-dropdown {
        background: none;
        position: absolute;
        top: 0;
        right: 0;
        height: 40px;
        justify-content: flex-end;
        svg {
          width: 16px;
          height: 16px;
          path {
            &[fill] {
              fill: ${colourPalette.neutral600};
            }
            &[stroke] {
              stroke: ${colourPalette.neutral600};
            }
          }
        }
        &:disabled {
          ${overridePrimeReactCssLayerDefinition`
            & {
              background: transparent !important;
            }
          `}
          svg {
            path {
              &[fill] {
                fill: ${colourPalette.neutral300};
              }
              &[stroke] {
                stroke: ${colourPalette.neutral300};
              }
            }
          }
        }
      }

      &.overlay-panel-visible > .p-autocomplete-dropdown > svg {
        transform: rotate(180deg);
      }

      &.p-invalid {
        & > .p-inputtext {
          border-color: ${colourPalette.rose500};
          &::placeholder {
            color: ${colourPalette.rose600};
          }
          &:focus,
          &:focus-within,
          &:active {
            border-color: ${colourPalette.blue500};
            box-shadow: 0px 0px 0px 4px ${hexToRGBA(colourPalette.blue600, 0.3)};
            background-color: ${colourPalette.neutral0};
          }
        }
        & + .p-dropdown-invalid-icon {
          display: block;
        }
      }

      &.is-disabled {
        & + .p-dropdown-invalid-icon {
          display: none;
        }
      }

      &.p-autocomplete-filled {
        & > .p-inputtext {
          background-color: ${colourPalette.neutral100};
          border: ${borders.borderSM} solid ${colourPalette.neutral100};
          &:hover {
            background-color: ${colourPalette.neutral200};
            border-color: ${colourPalette.neutral200};
          }
          &:focus,
          &:focus-within,
          &:active {
            border-color: ${colourPalette.neutral100};
            background-color: ${colourPalette.neutral100};
          }
          &:disabled {
            background-color: ${colourPalette.neutral50};
            border-color: ${colourPalette.neutral50};
          }
        }
        &.p-invalid {
          & > .p-inputtext {
            background-color: ${colourPalette.rose50};
            border-color: ${colourPalette.rose50};
          }
        }
      }
    }
  `,
};
