import { useContainerWidth } from 'hooks/useContainerWidth';
import { DataTableProps, DataTableValueArray } from 'primereact/datatable';
import { PropsWithChildren, useMemo, useRef } from 'react';
import { PDataTableStyle as S } from './PDataTable.style';

/**
 * @deprecated Use PDataTableV2 instead
 */
export const PDataTable = <T extends DataTableValueArray>(
  props: PropsWithChildren<DataTableProps<T> & { type?: 'plain' }>
) => {
  const container = useRef(null);
  const containerWidth = useContainerWidth(container, {
    disable: !props.paginator || !!props.paginatorTemplate,
  });
  const cleanProps = useMemo(() => (({ type, ...o }: any) => o)(props), [props]);
  const defaultProps = useMemo<DataTableProps<T>>(
    () => ({
      first: 0,
      from: 0,
      pageLinkSize: 5,
      paginatorTemplate:
        props.paginatorTemplate ||
        ({
          layout: `CurrentPageReport FirstPageLink PrevPageLink${
            (containerWidth || 0) > 500 ? ' PageLinks' : ''
          } NextPageLink LastPageLink RowsPerPageDropdown`,
        } as any),
      currentPageReportTemplate: '{first} - {last} of {totalRecords}',
      responsiveLayout: props.responsiveLayout || 'scroll',
      emptyMessage: props.emptyMessage || 'No results found',
    }),
    [props, containerWidth]
  );
  return (
    <div ref={container}>
      <S.DataTable {...defaultProps} {...cleanProps} />
    </div>
  );
};
