import styled, { css } from 'styled-components';
import { Checkbox } from 'primereact/checkbox';
import { borderRadius, borders, colourPalette, gap, hexToRGBA } from 'styles/styles';

export const PCheckboxBaseStyle = css`
  min-width: 16px;
  width: 16px;
  height: 16px;
  margin-top: 3px;
  box-shadow: none;

  .p-checkbox-box {
    width: 100%;
    height: 100%;
    border-radius: ${borderRadius.roundedXS};
    border: ${borders.borderSM} solid ${colourPalette.neutral200};
    background: ${colourPalette.neutral0};

    .checkbox-custom-icon {
      color: ${colourPalette.neutral0};
      path {
        stroke-width: 3px;
      }
    }
  }

  &:hover {
    .p-checkbox-box {
      border-color: ${colourPalette.neutral300};
      background: ${colourPalette.neutral100};
    }
  }

  &:focus,
  &:active,
  &:focus-within,
  &:focus-visible {
    .p-checkbox-box {
      outline: ${borders.borderMD} solid ${colourPalette.neutral0};
      box-shadow: 0px 0px 0px 4px ${hexToRGBA(colourPalette.blue600, 0.3)};
    }
  }

  &.p-highlight {
    .p-checkbox-box {
      border-color: ${colourPalette.blue500};
      background: ${colourPalette.blue500};
    }

    &:hover {
      .p-checkbox-box {
        border-color: ${colourPalette.blue600};
        background: ${colourPalette.blue600};
      }
    }
  }

  &.p-disabled {
    opacity: 1;
    .p-checkbox-box {
      border-color: ${colourPalette.neutral200};
      background: ${colourPalette.neutral100};
    }
  }

  &.p-disabled.p-highlight {
    .p-checkbox-box {
      border-color: ${colourPalette.neutral100};
      .p-checkbox-icon {
        color: ${colourPalette.neutral400};
        path {
          stroke: ${colourPalette.neutral400};
        }
      }
    }
  }

  &.p-invalid {
    .p-checkbox-box {
      border-color: ${colourPalette.rose500};
    }
  }

  &.p-invalid.p-highlight {
    .p-checkbox-box {
      background: ${colourPalette.rose500};
      border-color: ${colourPalette.rose500};
    }
  }
`;

export const PCheckboxStyle = {
  CheckboxWrapper: styled.span`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: ${gap.gap2};
  `,
  Checkbox: styled(Checkbox)`
    ${PCheckboxBaseStyle}

    &.no-label {
      margin-top: 0px;
    }
  `,
  Label: styled.label`
    user-select: none;
    line-height: 24px;
    cursor: pointer;
    color: ${colourPalette.neutral600};

    span.description {
      display: block;
      font-size: 13px;
      line-height: 20px;
      color: ${colourPalette.neutral400};
    }

    .p-checkbox-disabled + & {
      color: ${colourPalette.neutral300};
      pointer-events: none;
      span.description {
        color: ${colourPalette.neutral300};
      }
    }

    .p-invalid + & {
      color: ${colourPalette.rose500};
      span.description {
        color: ${colourPalette.rose500};
      }
    }
  `,
};
