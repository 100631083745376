import styled from 'styled-components';
import { colourPalette, fontSize, lineHeight } from 'styles/styles';

export const ImportModalMainSectionStyle = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    max-width: 800px;
  `,
  Title: styled.span`
    font-size: 24px;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 12px;
    color: ${colourPalette.neutral900};
  `,
  Subtitle: styled.span`
    font-size: ${fontSize.fontSizeSM};
    line-height: ${lineHeight.lineHeightMD};
    font-weight: 400;
    color: ${colourPalette.neutral500};
  `,
  Content: styled.div``,
};
