import styled from 'styled-components';
import { colourPalette, cssScreens } from 'styles/styles';

export const MapStepBarStyle = {
  Container: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${colourPalette.neutral300};
    height: 40px;
    padding: 0 40px;
    @media screen and (max-width: ${cssScreens.mobileLarge}) {
      display: none;
    }
  `,
  Errors: styled.div`
    display: flex;
    align-items: center;
    &.has-error {
      cursor: pointer;
    }
  `,
  ErrorText: styled.span`
    color: #67718b;
    font-size: 12px;
    line-height: 24px;
  `,
  ErrorIcon: styled.div`
    margin-right: 10px;
  `,
};
