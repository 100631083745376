import { FiltersTypes } from 'components/Filters/Filters.types';
import { format } from 'date-fns';
import { capitalizeFirstLetter } from './capitalize';
import { Icon } from 'components/Icon/Icon';

export const getFilterTitle = (data: {
  availableCategories: FiltersTypes.AvailableFilterCategory[];
  subjectTypeId: FiltersTypes.SubjectTypeId;
  subjectId: string;
}) => {
  if (['responseDate', 'dynamicTimeFrame'].includes(data.subjectTypeId)) return 'Response date';
  const exists = data.availableCategories.find((f) => f.subjectTypeId === data.subjectTypeId);
  const subjects = exists?.subjects || [];
  return subjects.length && subjects.length === 1
    ? subjects[0].subjectName
    : subjects.find((s) => s.subjectId === data.subjectId)?.subjectName ||
        capitalizeFirstLetter(String(data.subjectId));
};

const filterFormatDates = (filter: FiltersTypes.Filter | null) => {
  const startDate = (filter?.values as FiltersTypes.FilterDateValue).startDate;
  const endDate = (filter?.values as FiltersTypes.FilterDateValue).endDate;
  if (startDate && !endDate) return `From ${format(new Date(startDate), 'd MMM yyyy')}`;
  if (!startDate && endDate) return `To ${format(new Date(endDate), 'd MMM yyyy')}`;
  if (startDate && endDate)
    return `${format(new Date(startDate), 'd MMM yyyy')} - ${format(new Date(endDate), 'd MMM yyyy')}`;
  return '';
};

export const getFilterValue = (filter: FiltersTypes.Filter) => {
  let valuesString = '';
  let optionsString = '';
  if (filter?.operator) valuesString += filter.operator === 'is' ? 'Include:' : 'Exclude:';
  if (filter?.subjectTypeId === 'responseDate') {
    optionsString = filterFormatDates(filter);
  } else if (filter?.subjectTypeId === 'dynamicTimeFrame') {
    optionsString = capitalizeFirstLetter(filter.subjectId).replaceAll('_', ' ');
  } else {
    (filter?.values as FiltersTypes.FilterValue[]).forEach((v, i) => {
      const text = v.label || v.id;
      optionsString += !i ? text : `, ${text}`;
    });
  }
  if (!optionsString) return 'No values selected';
  return `${valuesString} ${optionsString}`;
};

export const getFilterIcon = (responseDataTypeId?: FiltersTypes.SubjectTypeId) => {
  switch (responseDataTypeId) {
    case 'variable':
      return <Icon icon="star01Solid" size={16} color="amber500" className="block" />;
    case 'questionResponse':
      return <Icon icon="checkSquareSolid" size={16} color="sky200" className="block" />;
    case 'responseDate':
      return <Icon icon="calendarSolid" size={16} color="cyan500" className="block" />;
    case 'dynamicTimeFrame':
      return <Icon icon="calendarSolid" size={16} color="cyan500" className="block" />;
    case 'themes':
      return <Icon icon="typeAquareSolid" size={16} color="purple400" className="block" />;
    case 'ingestion':
      return <Icon icon="arrowSquareUpSolid" size={16} color="green600" className="block" />;
    default:
      return <Icon icon="star01Solid" size={16} color="amber500" className="block" />;
  }
};
