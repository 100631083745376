import styled from 'styled-components';
import { Button } from 'primereact/button';
import {
  borderRadius,
  borders,
  colourPalette,
  fontSize,
  gap,
  hexToRGBA,
  lineHeight,
  overridePrimeReactCssLayerDefinition,
} from 'styles/styles';

export const PButtonLinkStyle = {
  ButtonLink: styled(Button)`
    min-width: auto;
    background: none;
    transition: 0.1s ease-out;
    justify-content: center;
    height: 24px;
    padding: 0;
    border-radius: ${borderRadius.roundedXXS};
    .button-link-custom-wrapper {
      display: flex;
      align-items: center;
      gap: ${gap.gap1};
    }
    .button-link-custom-icon-wrapper {
      flex: 0 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 16px;
      height: 16px;
      & > svg {
        width: 100%;
        height: 100%;
      }
    }
    .button-link-custom-label {
      font-size: ${fontSize.fontSizeSM};
      flex: 1 1 auto;
      font-weight: 500;
      line-height: ${lineHeight.lineHeightMD};
    }
    &:focus,
    &:focus-within,
    &:focus-visible {
      outline: ${borders.borderMD} solid ${colourPalette.neutral0};
      box-shadow: 0px 0px 0px 4px ${hexToRGBA(colourPalette.blue600, 0.3)};
    }

    &.button-link-severity-primary {
      background: transparent;
      .button-link-custom-icon-wrapper {
        svg path {
          &[fill] {
            fill: ${colourPalette.blue500};
          }
          &[stroke] {
            stroke: ${colourPalette.blue500};
          }
        }
      }
      .button-link-custom-label {
        color: ${colourPalette.blue500};
      }
      &:focus,
      &:focus-within,
      &:focus-visible {
        background: transparent;
      }
      &:hover {
        background: transparent;
        .button-link-custom-icon-wrapper {
          svg path {
            &[fill] {
              fill: ${colourPalette.blue700};
            }
            &[stroke] {
              stroke: ${colourPalette.blue700};
            }
          }
        }
        .button-link-custom-label {
          color: ${colourPalette.blue700};
        }
      }
      &:active {
        background: transparent;
      }
      &.p-disabled {
        ${overridePrimeReactCssLayerDefinition`
          & {
            background: transparent !important;
          }
        `}
        .button-link-custom-icon-wrapper {
          svg path {
            &[fill] {
              fill: ${colourPalette.neutral300};
            }
            &[stroke] {
              stroke: ${colourPalette.neutral300};
            }
          }
        }
        .button-link-custom-label {
          color: ${colourPalette.neutral300};
        }
      }
    }

    &.button-link-severity-secondary {
      background: transparent;
      .button-link-custom-icon-wrapper {
        svg path {
          &[fill] {
            fill: ${colourPalette.neutral500};
          }
          &[stroke] {
            stroke: ${colourPalette.neutral500};
          }
        }
      }
      .button-link-custom-label {
        color: ${colourPalette.neutral500};
      }
      &:focus,
      &:focus-within,
      &:focus-visible {
        background: transparent;
      }
      &:hover {
        background: transparent;
        .button-link-custom-icon-wrapper {
          svg path {
            &[fill] {
              fill: ${colourPalette.neutral900};
            }
            &[stroke] {
              stroke: ${colourPalette.neutral900};
            }
          }
        }
        .button-link-custom-label {
          color: ${colourPalette.neutral900};
        }
      }
      &:active {
        background: transparent;
      }
      &.p-disabled {
        ${overridePrimeReactCssLayerDefinition`
          & {
            background: transparent !important;
          }
        `}
        .button-link-custom-icon-wrapper {
          svg path {
            &[fill] {
              fill: ${colourPalette.neutral300};
            }
            &[stroke] {
              stroke: ${colourPalette.neutral300};
            }
          }
        }
        .button-link-custom-label {
          color: ${colourPalette.neutral300};
        }
      }
    }

    &.button-link-severity-invert {
      background: transparent;
      .button-link-custom-icon-wrapper {
        svg path {
          &[fill] {
            fill: ${colourPalette.neutral0};
          }
          &[stroke] {
            stroke: ${colourPalette.neutral0};
          }
        }
      }
      .button-link-custom-label {
        color: ${colourPalette.neutral0};
      }
      &:focus,
      &:focus-within,
      &:focus-visible {
        background: transparent;
        outline: ${borders.borderMD} solid ${colourPalette.neutral800};
      }
      &:hover {
        background: transparent;
        .button-link-custom-icon-wrapper {
          svg path {
            &[fill] {
              fill: ${colourPalette.neutral400};
            }
            &[stroke] {
              stroke: ${colourPalette.neutral400};
            }
          }
        }
        .button-link-custom-label {
          color: ${colourPalette.neutral400};
        }
      }
      &:active {
        background: transparent;
      }
      &.p-disabled {
        ${overridePrimeReactCssLayerDefinition`
          & {
            background: transparent !important;
          }
        `}
        .button-link-custom-icon-wrapper {
          svg path {
            &[fill] {
              fill: ${colourPalette.neutral600};
            }
            &[stroke] {
              stroke: ${colourPalette.neutral600};
            }
          }
        }
        .button-link-custom-label {
          color: ${colourPalette.neutral600};
        }
      }
    }
  `,
};
