import styled from 'styled-components';
import { colourPalette } from 'styles/styles';

export const ImportModalFooterStyle = {
  Container: styled.div<{ hideDivider?: boolean }>`
    height: 72px;
    padding: 0 24px;
    border-top: 2px solid ${colourPalette.neutral200};
    background-color: ${colourPalette.neutral0};
    box-shadow: 2px -2px 4px rgb(191, 198, 210, 0.4);
    position: relative;
    ${({ hideDivider }) =>
      hideDivider &&
      `border-top: 1px solid ${colourPalette.neutral0}; 
       box-shadow: none !important;
      `}
  `,
  ButtonsContainer: styled.div<{ hasBack: boolean }>`
    display: flex;
    justify-content: ${({ hasBack }) => (hasBack ? 'space-between' : 'flex-end')};
    align-items: center;
    height: 70px;
  `,
  RightButtons: styled.div`
    display: flex;
    gap: 8px;
  `,
};
