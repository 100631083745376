import styled from 'styled-components';
import { Toast } from 'primereact/toast';
import { fontSize, lineHeight, colourPalette } from 'styles/styles';

export const PToastStyle = {
  Toast: styled(Toast)`
    max-width: 280px;
    opacity: 1;
    top: 75px;

    & > div > .p-toast-message {
      background-color: white !important;
      opacity: 1;
      box-shadow: 0px 2px 12px 0px rgba(24, 30, 38, 0.12);
      border-radius: 2px 4px 4px 2px;
      border-left-width: 3px !important;
      border-left-style: solid;

      .p-toast-message-content {
        min-height: 128px;
        padding: 14px 16px;
        .p-toast-message-icon {
          margin-top: 1px;
        }
        .p-toast-message-text {
          margin: 1px 0 0 0;
          padding-left: 15px;
          flex: 1;
          & > .p-toast-summary {
            font-size: ${fontSize.fontSizeSM};
            line-height: ${lineHeight.lineHeightMD};
            color: ${colourPalette.neutral900};
            font-weight: 500;
          }
          & > .p-toast-detail {
            font-size: ${fontSize.fontSizeSM};
            line-height: ${lineHeight.lineHeightMD};
            color: ${colourPalette.neutral600};
          }
        }
      }

      .p-toast-message-icon {
        height: 16px !important;
        width: 16px !important;
        position: relative;
        top: 1px;
      }

      .p-toast-icon-close {
        width: 16px;
        height: 16px;
        min-width: 16px;
        margin-top: 2px;
        & > .p-toast-icon-close-icon {
          path {
            fill: ${colourPalette.neutral500};
          }
          height: 12px !important;
          width: 12px !important;
          &:hover {
            path {
              fill: ${colourPalette.neutral600};
            }
          }
        }
      }

      &.summary-only > .p-toast-message-content {
        min-height: 48px;
        .p-toast-message-text > .p-toast-summary {
          font-family: 'SuisseIntl', sans-serif;
          font-weight: 400;
        }
      }

      &.p-toast-message-success {
        border-left-color: ${colourPalette.green500};
        .p-toast-message-icon {
          path {
            fill: ${colourPalette.green500};
          }
        }
      }

      &.p-toast-message-error {
        border-left-color: ${colourPalette.rose600};
        .p-toast-message-icon {
          path {
            fill: ${colourPalette.rose600};
          }
        }
      }

      &.p-toast-message-warn {
        border-left-color: ${colourPalette.amber500};
        .p-toast-message-icon {
          path {
            fill: ${colourPalette.amber500};
          }
        }
      }

      &.p-toast-message-info {
        border-left-color: ${colourPalette.blue600};
        .p-toast-message-icon {
          path {
            fill: ${colourPalette.blue600};
          }
        }
      }

      /* Custom Toast Animation */
      &.toast-anim-enter {
        opacity: 0;
        transform: translateX(100%);
      }
      &.toast-anim-enter-active {
        opacity: 1;
        transform: translateX(0);
        transition: opacity 300ms, transform 300ms;
        transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
      }
      &.toast-anim-exit {
        opacity: 1;
        transform: translateX(0);
      }
      &.toast-anim-exit-active {
        opacity: 0;
        transform: translateX(100%);
        transition: opacity 300ms, transform 300ms;
        transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
      }
    }
  `,
};
