import styled from 'styled-components';
import { colourPalette, fontSize, lineHeight } from 'styles/styles';

export const ImportModalSyntheticFormStyle = {
  Form: styled.form`
    display: flex;
    flex-direction: column;
    height: calc(100vh);
    max-height: calc(100vh);
    h6 {
      font-weight: 400;
      font-size: ${fontSize.fontSizeSM};
      line-height: ${lineHeight.lineHeightMD};
      color: ${colourPalette.neutral500};
      margin-bottom: 32px;
    }
  `,
  Content: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
    max-width: 400px;
    margin: 0 auto;
  `,
  Name: styled.div`
    margin-top: 24px;
  `,
};
