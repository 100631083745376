import styled from 'styled-components';
import { fontSize, lineHeight, colourPalette } from 'styles/styles';

export const NotificationContentLayoutStyle = {
  Container: styled.div`
    border-top: 8px solid ${colourPalette.blue600};
  `,
  Frontpage: styled.div`
    height: 100px;
    width: 100%;
    position: relative;
    background-color: ${colourPalette.blue600};
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      vertical-align: bottom;
    }
  `,
  Content: styled.div`
    padding: 40px 32px 32px;
    & .import-notification-action-buttons {
      display: flex;
      gap: 8px;
      align-items: center;
    }
  `,
  Title: styled.p`
    font-size: 24px;
    line-height: 32px;
    color: ${colourPalette.neutral900};
    margin: 0 0 12px;
  `,
  Description: styled.div`
    font-size: ${fontSize.fontSizeSM};
    line-height: ${lineHeight.lineHeightMD};
    color: ${colourPalette.neutral500};
    margin: 0 0 24px;
    b {
      font-weight: 500 !important;
      color: ${colourPalette.neutral900};
    }
  `,
};
