import styled from 'styled-components';
import { colourPalette, fontSize, lineHeight } from 'styles/styles';
import { PMessage } from 'components/prime/PMessage/PMessage';

export const ImportModalSyntheticFormErrorStyle = {
  Container: styled.div`
    background-color: ${colourPalette.neutral100};
    padding: 16px;
    display: flex;
    border-radius: 4px;
    align-items: center;
    margin-bottom: 32px;
    color: ${colourPalette.neutral600};
    & > svg {
      margin-right: 16px;
    }
  `,
  Message: styled(PMessage)`
    margin-top: 16px;
  `,
  MessageText: styled.span`
    font-weight: 400;
    font-size: ${fontSize.fontSizeSM};
    line-height: ${lineHeight.lineHeightMD};
    color: ${colourPalette.neutral600};
  `,
  BlueInfo: styled.div`
    margin-top: 16px;
    background-color: ${colourPalette.neutral100};
    border-radius: 4px;
    padding: 24px;
    text-align: center;
    h3 {
      color: ${colourPalette.neutral900};
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      margin: 0;
    }
    p {
      margin: 8px 0 16px 0;
      color: ${colourPalette.neutral500};
      line-height: 20px;
    }
    a {
      display: inline-block;
    }
  `,
};
