import { css } from 'styled-components';
import { colourPalette } from 'styles/styles';

export const LinkTextBaseStyle = css`
  font-size: inherit;
  line-height: inherit;
  border-radius: 2px;
  text-underline-offset: 2px;
  &.link-text-primary {
    color: ${colourPalette.blue500};
    text-decoration: none;
    &:focus,
    &:focus-within {
      text-decoration: underline;
      text-decoration-color: ${colourPalette.blue500};
      outline: 2px solid ${colourPalette.blue500};
    }
    &:hover {
      text-decoration: underline;
      text-decoration-color: ${colourPalette.blue500};
    }
    &:active {
      color: ${colourPalette.blue600};
      text-decoration: underline;
      text-decoration-color: ${colourPalette.blue600};
    }
  }
  &.link-text-secondary {
    color: ${colourPalette.neutral600};
    text-decoration: underline !important;
    text-decoration-color: ${colourPalette.neutral600}24 !important;
    &:focus,
    &:focus-within {
      text-decoration-color: ${colourPalette.neutral600} !important;
      outline: 2px solid ${colourPalette.blue500};
    }
    &:hover {
      text-decoration-color: ${colourPalette.neutral600} !important;
    }
    &:active {
      color: ${colourPalette.neutral900};
      text-decoration-color: ${colourPalette.neutral900} !important;
    }
  }
  &.link-text-dark {
    color: ${colourPalette.neutral0};
    text-decoration: underline !important;
    text-decoration-color: ${colourPalette.neutral0}24 !important;
    &:focus,
    &:focus-within {
      text-decoration-color: ${colourPalette.neutral0} !important;
      outline: 2px solid ${colourPalette.blue500};
    }
    &:hover {
      text-decoration-color: ${colourPalette.neutral0} !important;
    }
    &:active {
      color: ${colourPalette.neutral0}70;
      text-decoration-color: ${colourPalette.neutral0}70 !important;
    }
  }
`;
