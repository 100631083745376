import styled from 'styled-components';
import { ReactComponent as SagoLogoSvg } from 'assets/svg/custom-themes/sago.svg';
import { colourPalette } from 'styles/styles';
import { YabbleLogo } from 'components/YabbleLogo/YabbleLogo';

export const LeftMenuLogoStyle = {
  Container: styled.div``,
  Logo: styled(YabbleLogo)`
    .left-menu-light-theme & {
      fill: ${colourPalette.neutral900};
    }
    .top-bar-title-logo-mypanel-pages & {
      fill: ${colourPalette.neutral900};
    }
  `,
  SagoLogo: styled(SagoLogoSvg)`
    display: block;
    fill: white;
    height: 32px;
    width: 102px;
    .left-menu-light-theme & {
      fill: ${colourPalette.neutral900};
    }
    .top-bar-title-logo-mypanel-pages & {
      fill: ${colourPalette.neutral900};
    }
  `,
};
