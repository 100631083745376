import styled from 'styled-components';
import { colourPalette } from 'styles/styles';

export const DetailsPanelContentLoadingStyle = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 94%;
  `,
  Text: styled.p`
    color: ${colourPalette.neutral500};
  `,
};
