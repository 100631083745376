import React from 'react';
import { TypeOfDataIndustryDropdown } from './components/TypeOfDataIndustryDropdown/TypeOfDataIndustryDropdown';
import { UploadStepTypeOfDataStyle as S } from './UploadStepTypeOfData.style';
import { TypeOfDataNameInput } from './components/TypeOfDataNameInput/TypeOfDataNameInput';
import { PTooltip } from 'components/prime/PTooltip/PTooltip';
import { TypeOfDataImportNameInput } from './components/TypeOfDataImportNameInput/TypeOfDataImportNameInput';
import { Icon } from 'components/Icon/Icon';
import { Box } from 'components/layouts/primitives/Box/Box';
import { HStack } from 'components/layouts/primitives/Stack/Stack';

export const UploadStepTypeOfData: React.FC = () => {
  return (
    <S.Container>
      <S.Content>
        <Box padding={['paddingNone', 'paddingNone', 'paddingXL']}>
          <HStack gap="gap2" align="center">
            <S.Title>What type of data are you importing?</S.Title>
            <Box className="type-of-data-header-info">
              <Icon size={16} icon="infoCircleSolid" color="gray400" className="block" />
              <PTooltip
                target=".type-of-data-header-info"
                position="bottom"
                content="This contextualizes your data, allowing us to ensure we use the models that will generate the most accurate output from your dataset."
              />
            </Box>
          </HStack>
        </Box>
        <S.Fields>
          <TypeOfDataIndustryDropdown />
          <TypeOfDataNameInput />
          <TypeOfDataImportNameInput />
        </S.Fields>
      </S.Content>
    </S.Container>
  );
};
