import styled from 'styled-components';

import { colourPalette, fontSize, lineHeight } from 'styles/styles';

export const DetailsPanelEmptyStateStyle = {
  Container: styled.div`
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  Title: styled.p`
    margin: 0;
    padding: 24px 8px 48px;
    font-size: ${fontSize.fontSizeSM};
    line-height: ${lineHeight.lineHeightMD};
    font-weight: 600;
    color: ${colourPalette.neutral400};
    text-align: center;
  `,
};
