import styled from 'styled-components';
import { colourPalette } from 'styles/styles';
import { ReactComponent as BurgerIcon } from 'assets/svg/components/menu/topbar-hamburger-collapse.svg';

export const LeftMenuBurgerStyle = {
  Icon: styled(BurgerIcon)`
    vertical-align: bottom;
    cursor: pointer;

    & > line,
    g {
      transition: 0.3s;
    }

    & > line:first-child {
      stroke-dashoffset: 80;
      stroke-dasharray: 100;
    }

    path {
      transform: translateX(5px);
    }

    &:hover {
      & > line:last-child {
        transform: translateX(10px);
      }

      & > line:first-child {
        stroke-dashoffset: 92;
      }

      g {
        transform: translateX(-6px);
      }
    }

    .left-menu-light-theme & {
      line {
        stroke: ${colourPalette.neutral900};
      }
      path {
        stroke: ${colourPalette.neutral900};
        fill: transparent;
      }
    }
  `,
};
