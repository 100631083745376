import styled from 'styled-components';
import { PDropdown } from 'components/prime/PDropdown/PDropdown';
import { PSkeleton } from 'components/prime/PSkeleton/PSkeleton';

export const ListPanelSearchStyle = {
  Container: styled.div``,
  Dropdown: styled(PDropdown)`
    margin-bottom: 16px;
  `,
  ProjectDropdownSkeleton: styled(PSkeleton)`
    margin-bottom: 16px;
  `,
};
