import styled from 'styled-components';
import { colourPalette } from 'styles/styles';
import { PButtonIcon } from 'components/prime/PButton/PButtonIcon';

export const DetailsPanelContentStyle = {
  Container: styled.div`
    flex: 1 1 auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    & > .details-panel-content-info {
      flex: 1 1 auto;
      padding: 24px 24px 0;
      overflow-y: overlay;
    }
    & > .details-panel-bottom {
      flex: 0 0 fit-content;
      padding: 24px;
      background-color: ${colourPalette.neutral0};
    }
  `,
  BackBtn: styled(PButtonIcon)`
    display: none;
    @media screen and (max-width: 600px) {
      display: block;
      margin-bottom: 8px;
    }
  `,
};
