import styled from 'styled-components';
import { colourPalette } from 'styles/styles';
export const FileUploadItemMultipleStyle = {
  Container: styled.div`
    max-width: 220px;
    padding: 0;
  `,
  Item: styled.div`
    background: ${colourPalette.neutral100};
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 8px;
    gap: 4px;
  `,
  ItemText: styled.span`
    font-weight: 500 !important;
    font-size: 12px;
    line-height: 24px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    flex: 1;
    text-align: left;
  `,
  IconContainer: styled.div`
    width: 20px;
    cursor: pointer;
  `,
};
