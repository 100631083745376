export const cssScreens = {
  mobile: '480px',
  mobileLarge: '768px',
  tablet: '1024px',
  tabletLarge: '1200px',
  laptop: '1396px',
  desktop: '1626px',
};

/**
 * Converts the hexadecimal color value to the corresponding rgba format
 * @param hexCode The hex value you want to convert
 * @param opacity The opacity 0 to 1
 * @returns rgba formatted color
 */
export function hexToRGBA(hexCode: string, opacity = 1) {
  let hex = hexCode.replace('#', '');
  let op = opacity;

  /* Backward compatibility for 3 digit hex value */
  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  /* Backward compatibility for whole number based opacity values */
  if (opacity > 1 && opacity <= 100) {
    op = opacity / 100;
  }

  return `rgba(${r},${g},${b},${op})`;
}

export const overridePrimeReactCssLayerDefinition = (strings: TemplateStringsArray, ...values: unknown[]) => {
  let result = '';

  strings.forEach((str, i) => {
    result += str + (values[i] || '');
  });

  return `@layer primereact {
    ${result}
  }`;
};

export const colourPalette = {
  // Slate
  slate50: '#f8fafc',
  slate100: '#f1f5f9',
  slate200: '#e2e8f0',
  slate300: '#cbd5e1',
  slate400: '#94a3b8',
  slate500: '#64748b',
  slate600: '#475569',
  slate700: '#334155',
  slate800: '#1e293b',
  slate900: '#0f172a',
  slate950: '#020617',
  // Gray
  gray50: '#f9fafb',
  gray100: '#f3f4f6',
  gray200: '#e5e7eb',
  gray300: '#d1d5db',
  gray400: '#9ca3af',
  gray500: '#6b7280',
  gray600: '#4b5563',
  gray700: '#374151',
  gray800: '#1f2937',
  gray900: '#111827',
  gray950: '#030712',
  // Neutral
  neutral0: '#ffffff',
  neutral50: '#fafafa',
  neutral100: '#f5f5f5',
  neutral200: '#e5e5e5',
  neutral300: '#d4d4d4',
  neutral400: '#a3a3a3',
  neutral500: '#737373',
  neutral600: '#525252',
  neutral700: '#404040',
  neutral800: '#262626',
  neutral900: '#171717',
  neutral950: '#0a0a0a',
  // Stone
  stone50: '#fafaf9',
  stone100: '#f5f5f4',
  stone200: '#e7e5e4',
  stone300: '#d6d3d1',
  stone400: '#a8a29e',
  stone500: '#78716c',
  stone600: '#57534e',
  stone700: '#44403c',
  stone800: '#292524',
  stone900: '#1c1917',
  stone950: '#0c0a09',
  // Red
  red50: '#fef2f2',
  red100: '#fee2e2',
  red200: '#fecaca',
  red300: '#fca5a5',
  red400: '#f87171',
  red500: '#ef4444',
  red600: '#dc2626',
  red700: '#b91c1c',
  red800: '#991b1b',
  red900: '#7f1d1d',
  red950: '#450a0a',
  // Orange
  orange50: '#fff7ed',
  orange100: '#ffedd5',
  orange200: '#fed7aa',
  orange300: '#fdba74',
  orange400: '#fb923c',
  orange500: '#f97316',
  orange600: '#ea580c',
  orange700: '#c2410c',
  orange800: '#9a3412',
  orange900: '#7c2d12',
  orange950: '#431407',
  // Amber
  amber50: '#fffbeb',
  amber100: '#fef3c7',
  amber200: '#fde68a',
  amber300: '#fcd34d',
  amber400: '#fbbf24',
  amber500: '#f59e0b',
  amber600: '#d97706',
  amber700: '#b45309',
  amber800: '#92400e',
  amber900: '#78350f',
  amber950: '#451a03',
  // Yellow
  yellow50: '#fefce8',
  yellow100: '#fef9c3',
  yellow200: '#fef08a',
  yellow300: '#fde047',
  yellow400: '#facc15',
  yellow500: '#eab308',
  yellow600: '#ca8a04',
  yellow700: '#a16207',
  yellow800: '#854d0e',
  yellow900: '#713f12',
  yellow950: '#422006',
  // Lime
  lime50: '#f7fee7',
  lime100: '#ecfccb',
  lime200: '#d9f99d',
  lime300: '#bef264',
  lime400: '#a3e635',
  lime500: '#84cc16',
  lime600: '#65a30d',
  lime700: '#4d7c0f',
  lime800: '#3f6212',
  lime900: '#365314',
  lime950: '#1a2e05',
  // Green
  green50: '#f0fdf4',
  green100: '#dcfce7',
  green200: '#bbf7d0',
  green300: '#86efac',
  green400: '#4ade80',
  green500: '#22c55e',
  green600: '#16a34a',
  green700: '#15803d',
  green800: '#166534',
  green900: '#14532d',
  green950: '#052e16',
  // Emerald
  emerald50: '#ecfdf5',
  emerald100: '#d1fae5',
  emerald200: '#a7f3d0',
  emerald300: '#6ee7b7',
  emerald400: '#34d399',
  emerald500: '#10b981',
  emerald600: '#059669',
  emerald700: '#047857',
  emerald800: '#065f46',
  emerald900: '#064e3b',
  emerald950: '#022c22',
  // Teal
  teal50: '#f0fdfa',
  teal100: '#ccfbf1',
  teal200: '#99f6e4',
  teal300: '#5eead4',
  teal400: '#2dd4bf',
  teal500: '#14b8a6',
  teal600: '#0d9488',
  teal700: '#0f766e',
  teal800: '#115e59',
  teal900: '#134e4a',
  teal950: '#042f2e',
  // Cyan
  cyan50: '#ecfeff',
  cyan100: '#cffafe',
  cyan200: '#a5f3fc',
  cyan300: '#67e8f9',
  cyan400: '#22d3ee',
  cyan500: '#06b6d4',
  cyan600: '#0891b2',
  cyan700: '#0e7490',
  cyan800: '#155e75',
  cyan900: '#164e63',
  cyan950: '#083344',
  // Sky
  sky50: '#f0f9ff',
  sky100: '#e0f2fe',
  sky200: '#bae6fd',
  sky300: '#7dd3fc',
  sky400: '#38bdf8',
  sky500: '#0ea5e9',
  sky600: '#0284c7',
  sky700: '#0369a1',
  sky800: '#075985',
  sky900: '#0c4a6e',
  sky950: '#082f49',
  // Blue
  blue50: '#eff6ff',
  blue100: '#dbeafe',
  blue200: '#bfdbfe',
  blue300: '#93c5fd',
  blue400: '#60a5fa',
  blue500: '#3b82f6',
  blue600: '#2563eb',
  blue700: '#1d4ed8',
  blue800: '#1e40af',
  blue900: '#1e3a8a',
  blue950: '#172554',
  // Indigo
  indigo50: '#eef2ff',
  indigo100: '#e0e7ff',
  indigo200: '#c7d2fe',
  indigo300: '#a5b4fc',
  indigo400: '#818cf8',
  indigo500: '#6366f1',
  indigo600: '#4f46e5',
  indigo700: '#4338ca',
  indigo800: '#3730a3',
  indigo900: '#312e81',
  indigo950: '#1e1b4b',
  // Violet
  violet50: '#f5f3ff',
  violet100: '#ede9fe',
  violet200: '#ddd6fe',
  violet300: '#c4b5fd',
  violet400: '#a78bfa',
  violet500: '#8b5cf6',
  violet600: '#7c3aed',
  violet700: '#6d28d9',
  violet800: '#5b21b6',
  violet900: '#4c1d95',
  violet950: '#2e1065',
  // Purple
  purple50: '#faf5ff',
  purple100: '#f3e8ff',
  purple200: '#e9d5ff',
  purple300: '#d8b4fe',
  purple400: '#c084fc',
  purple500: '#a855f7',
  purple600: '#9333ea',
  purple700: '#7e22ce',
  purple800: '#6b21a8',
  purple900: '#581c87',
  purple950: '#3b0764',
  // Fuchsia
  fuchsia50: '#fdf4ff',
  fuchsia100: '#fae8ff',
  fuchsia200: '#f5d0fe',
  fuchsia300: '#f0abfc',
  fuchsia400: '#e879f9',
  fuchsia500: '#d946ef',
  fuchsia600: '#c026d3',
  fuchsia700: '#a21caf',
  fuchsia800: '#86198f',
  fuchsia900: '#701a75',
  fuchsia950: '#4a044e',
  // Pink
  pink50: '#fdf2f8',
  pink100: '#fce7f3',
  pink200: '#fbcfe8',
  pink300: '#f9a8d4',
  pink400: '#f472b6',
  pink500: '#ec4899',
  pink600: '#db2777',
  pink700: '#be185d',
  pink800: '#9d174d',
  pink900: '#831843',
  pink950: '#500724',
  // Rose
  rose50: '#fff1f2',
  rose100: '#ffe4e6',
  rose200: '#fecdd3',
  rose300: '#fda4af',
  rose400: '#fb7185',
  rose500: '#f43f5e',
  rose600: '#e11d48',
  rose700: '#be123c',
  rose800: '#9f1239',
  rose900: '#881337',
  rose950: '#4c0519',
};

export const borders = {
  borderNone: '0px',
  borderSM: '1px',
  borderMD: '2px',
  borderLG: '4px',
  borderXL: '8px',
};

export const borderRadius = {
  roundedNone: '0px',
  roundedXXS: '2px',
  roundedXS: '4px',
  roundedSM: '6px',
  roundedMD: '8px',
  roundedLG: '12px',
  roundedXL: '16px',
  rounded2XL: '24px',
  rounded3XL: '32px',
  rounded4XL: '40px',
  roundedFull: '9999px',
};

export const shadow = {
  shadowSM: '0px 1px 2px 0px rgba(0, 0, 0, 0.05)',
  shadowDefault: '0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px -1px rgba(0, 0, 0, 0.10)',
  shadowMD: '0px 4px 6px -1px rgba(0, 0, 0, 0.10), 0px 2px 4px -2px rgba(0, 0, 0, 0.10)',
  shadowLG: '0px 10px 15px -3px rgba(0, 0, 0, 0.10), 0px 4px 6px -4px rgba(0, 0, 0, 0.10)',
  shadowXL: '0px 20px 25px -5px rgba(0, 0, 0, 0.10), 0px 8px 10px -6px rgba(0, 0, 0, 0.10)',
  shadow2XL: '0px 25px 50px -12px rgba(0, 0, 0, 0.25)',
  shadowInner: '0px 2px 4px 0px rgba(0, 0, 0, 0.05) inset',
  shadowNone: '0 0 0 0 rgba(0, 0, 0, 0.00)',
};

export const stroke = {
  strokeNone: '0px',
  strokeWeak: '1px',
  strokeBase: '1.5px',
  strokeStrong: '2px',
};

export const padding = {
  paddingNone: '0px',
  paddingXXXS: '2px',
  paddingXXS: '4px',
  paddingXS: '6px',
  paddingSM: '8px',
  paddingInput: '10px',
  paddingMD: '12px',
  paddingLG: '16px',
  paddingXL: '24px',
  padding2XL: '32px',
  padding3XL: '48px',
};

export const gap = {
  gapNone: '0px',
  gap0: '2px',
  gap1: '4px',
  gap2: '8px',
  gap3: '12px',
  gap4: '16px',
  gap5: '24px',
  gap6: '32px',
  gap7: '48px',
  gap8: '64px',
  gap9: '80px',
};

export const fontSize = {
  fontSize4XL: '48px',
  fontSize3XL: '40px',
  fontSize2XL: '32px',
  fontSizeXL: '24px',
  fontSizeLG: '20px',
  fontSizeMD: '17px',
  fontSizeSM: '15px',
  fontSizeXS: '13px',
};

export const lineHeight = {
  lineHeight6XL: '60px',
  lineHeight4XL: '52px',
  lineHeight3XL: '46px',
  lineHeight2XL: '38px',
  lineHeightXL: '32px',
  lineHeightLG: '28px',
  lineHeightMD: '24px',
  lineHeightSM: '22px',
};

export const fontWeight = {
  regular: 400,
  medium: 500,
  semiBold: 600,
};
