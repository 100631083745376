import React, { useEffect, useMemo, useRef } from 'react';
import { ItemTemplateOptions } from 'primereact/fileupload';
import { FileUploadItemMultipleStyle as S } from './FileUploadItemMultiple.style';
import { Icon } from 'components/Icon/Icon';

export const FileUploadItemMultiple: React.FC<{
  file: File;
  options: ItemTemplateOptions;
  maxFileSize?: number;
  accept?: string;
}> = ({ file, options, maxFileSize, accept }) => {
  const iconRef = useRef<HTMLDivElement>(null);
  const extensions = useMemo(() => (!!accept ? accept.split(',').map((ext) => ext.slice(1)) : []), [accept]);

  useEffect(() => {
    if (
      (!extensions.includes(file.name.split('.').at(-1) || '') ||
        (typeof maxFileSize === 'number' && file.size >= maxFileSize)) &&
      iconRef?.current
    ) {
      setTimeout(() => iconRef.current?.click());
    }
  }, [file, maxFileSize, extensions]);

  return (
    <S.Container>
      <S.Item>
        <S.ItemText>{file.name}</S.ItemText>
        <S.IconContainer ref={iconRef} onClick={options.onRemove}>
          <Icon icon="xLine" size={16} />
        </S.IconContainer>
      </S.Item>
    </S.Container>
  );
};
