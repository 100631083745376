import styled from 'styled-components';
import { colourPalette } from 'styles/styles';
export const DetailsPanelContentTitleStyle = {
  Container: styled.div`
    display: flex;
    justify-content: space-between;
  `,
  Title: styled.p`
    margin: 0;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color: ${colourPalette.neutral900};
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    cursor: pointer;
    padding-right: 10px;
    &.is-extended {
      -webkit-line-clamp: initial;
    }
  `,
  Options: styled.div`
    flex: 0 0 fit-content;
    display: flex;
    align-items: center;
    height: 25px;
  `,
  OptionTemplate: styled.div``,
};
