import styled from 'styled-components';
import { colourPalette } from 'styles/styles';

export const MapStepColumnMapToItemStyle = {
  Container: styled.div`
    color: ${colourPalette.neutral600};
    span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  `,
  Divider: styled.div`
    font-size: 11px;
    line-height: 24px;
    font-weight: 600;
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    border-top: 1px solid ${colourPalette.neutral300};
    background-color: ${colourPalette.neutral0} !important;
    padding-left: 6px;
    color: ${colourPalette.neutral400};
  `,
  Disabled: styled.div`
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    background-color: ${colourPalette.neutral0} !important;
    color: ${colourPalette.neutral400}!important;
    padding-left: 6px;
  `,
  Item: styled.div`
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    padding-left: 6px;
    padding-right: 20px;
  `,
};
