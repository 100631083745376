import React, { useMemo } from 'react';
import { useClassNames } from 'hooks/useClassNames';
import { InputText, InputTextProps } from 'primereact/inputtext';
import { PInputTextStyle as S } from './PInputText.style';
import { Icon } from 'components/Icon/Icon';
import { HStack } from 'components/layouts/primitives/Stack/Stack';

export type PInputTextPropsSeverity = 'filled' | 'outlined';

export type PInputTextProps = Omit<InputTextProps, 'size'> & {
  invalid?: boolean | null;
  size?: 'small' | 'large' | 'default';
  rightIcon?: React.ReactNode;
  leftIcon?: React.ReactNode;
  severity?: PInputTextPropsSeverity;
  viewOnly?: boolean;
};
export const PInputText = React.forwardRef<typeof InputText, PInputTextProps>((props, ref) => {
  const { rightIcon, leftIcon } = props;
  const cleanProps = useMemo(
    () => (({ rightIcon, leftIcon, invalid, viewOnly, inactive, severity, ...o }: any) => o)(props),
    [props]
  );

  const containerClasses = useClassNames(
    {
      'p-input-icon-right': !!rightIcon,
      'p-input-icon-left': !!leftIcon,
      'view-only-input': props.viewOnly,
      'is-invalid': !!props.invalid,
      'is-disabled': !!props.disabled,
      'has-value': !!props.value,
    },
    props.className
  );

  const inputClasses = useClassNames({
    'p-invalid': props.invalid,
    'p-inputtext-sm': props.size === 'small',
    'p-inputtext-lg': props.size === 'large',
    'view-only-input': props.viewOnly,
    [`p-inputtext-${props.severity || 'outlined'}`]: true,
  });

  return (
    <S.InputTextContainer className={containerClasses}>
      {!!leftIcon && (
        <HStack align="center" className="input-text-icon-wrapper left">
          {leftIcon}
        </HStack>
      )}
      <S.InputText {...cleanProps} className={inputClasses} id={props.id ?? props.name} ref={ref as any} />
      {(!!props.invalid || !!rightIcon) && (
        <HStack align="center" gap="gap2" className="input-text-icon-wrapper right">
          {props.invalid && <Icon icon="alertCircleSolid" className="input-text-invalid-icon" />}
          {rightIcon}
        </HStack>
      )}
    </S.InputTextContainer>
  );
});
