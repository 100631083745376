import styled from 'styled-components';
import { colourPalette, cssScreens, fontSize, lineHeight } from 'styles/styles';

export const HeyYabbleCountStepSummaryStyle = {
  Container: styled.div`
    width: 30%;
    background: ${colourPalette.neutral100};
    border-radius: 8px;
    margin: 10px 0 40px 20px;
    padding: 24px 24px 12px 24px;
    position: sticky;
    top: 0;
    @media screen and (max-width: ${cssScreens.tablet}) {
      width: 100%;
      margin: 0;
      margin-top: -10px;
      position: relative;
      top: auto;
    }
  `,
  Row: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  `,
  SecondaryRow: styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 16px;
    margin-top: -9px;
  `,
  Title: styled.span`
    font-size: 12px;
    line-height: 160%;
    color: ${colourPalette.neutral400};
    font-weight: 600;
  `,
  Text: styled.span`
    font-size: ${fontSize.fontSizeSM};
    line-height: ${lineHeight.lineHeightMD};
    color: ${colourPalette.neutral600};
    .sub > & {
      color: ${colourPalette.neutral500};
      &:first-child {
        padding-left: 24px;
        position: relative;
        &::before {
          content: '';
          display: block;
          width: 5px;
          height: 5px;
          background-color: ${colourPalette.neutral500};
          border-radius: 50%;
          position: absolute;
          top: 5px;
          left: 7px;
        }
      }
    }
  `,
  Description: styled.span`
    font-size: 12px;
    line-height: 24px;
    color: ${colourPalette.neutral500};
  `,
  Total: styled.span`
    font-size: ${fontSize.fontSizeSM};
    line-height: ${lineHeight.lineHeightMD};
    color: ${colourPalette.neutral900};
    font-weight: 600;
  `,
};
