import styled from 'styled-components';
import { ReactComponent as ImportSvg } from 'assets/svg/modals/import-modal/import.svg';
import { colourPalette } from 'styles/styles';
export const FileUploadEmptyStateStyle = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 320px;
  `,
  Icon: styled(ImportSvg)``,
  Title: styled.p`
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    margin-top: 12px;
    margin-bottom: 16px;
    color: ${colourPalette.neutral500};
    text-align: center;
  `,
  Description: styled.span`
    font-size: 12px;
    line-height: 20px;
    color: ${colourPalette.neutral400};
    text-align: center;
  `,
};
