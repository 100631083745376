import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { LinkTextBaseStyle } from './LinkText.style.base';
import { colourPalette } from 'styles/styles';
export const LinkTextStyle = {
  Container: styled.div``,
  NativeAnchor: styled.a`
    ${LinkTextBaseStyle}
  `,
  RouterLink: styled(Link)`
    ${LinkTextBaseStyle}
  `,
  Disabled: styled.span`
    cursor: not-allowed;
    color: ${colourPalette.neutral400};
  `,
};
