import styled from 'styled-components';
import { ReactComponent as BurgerIcon } from 'assets/svg/components/menu/topbar-hamburger-default.svg';

export const TopBarBurgerStyle = {
  Icon: styled(BurgerIcon)<{ $inReact: boolean }>`
    margin-left: 0px;
    vertical-align: bottom;
    cursor: pointer;
    margin-right: 0px;
    overflow: hidden;
    flex: 0 0 0;
    width: 0px;
    transition: 0.2s;
    body.no-side-menu & {
      display: none !important;
    }

    line {
      transition: 0.3s;

      &:first-child {
        stroke-dashoffset: 80;
        stroke-dasharray: 100;
      }
    }

    &:hover {
      line {
        &:first-child {
          stroke-dashoffset: 92;
        }
        &:last-child {
          transform: translateX(10px);
        }
      }
    }

    body.side-menu-hidden & {
      flex: 0 0 24px;
      width: 24px;
      margin-right: 20px;
      margin-left: ${({ $inReact }) => ($inReact ? '24px' : '0px')};
    }
  `,
};
