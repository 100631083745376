import { PDataTable } from 'components/prime/PDataTable/PDataTable';
import styled from 'styled-components';
import { colourPalette, fontSize, lineHeight } from 'styles/styles';

export const ListPanelFiltersTableStyle = {
  Container: styled.div`
    flex: 1 1 auto;
    display: flex;
    & > div {
      flex: 1;
      display: flex;
    }
  `,
  DataTable: styled(PDataTable)`
    &.p-datatable.p-component {
      flex: 1;
      display: flex;
      flex-direction: column;
      border: none;
      & > .p-datatable-wrapper {
        flex: 1;
      }
      & .p-datatable-table {
        table-layout: fixed;
        width: 100%;
      }
      & .p-datatable-thead {
        display: none;
      }
      & .p-datatable-tbody > tr {
        & > td {
          border: none;
          height: 38px;
          padding: 0;
        }
      }
      & .p-paginator.p-component {
        position: sticky;
        bottom: 0;
        padding-bottom: 24px;
        z-index: 1;
      }
      & .p-datatable-emptymessage td {
        font-size: ${fontSize.fontSizeSM};
        line-height: ${lineHeight.lineHeightMD};
        font-weight: 600;
        color: ${colourPalette.neutral400};
        text-align: center;
      }
    }
  `,
};
