import styled from 'styled-components';
import { colourPalette, fontSize, hexToRGBA, lineHeight } from 'styles/styles';

export const DropdownMenuItemStyle = {
  Container: styled.a<{ $isMuted?: boolean; $variableHeight?: boolean }>`
    height: ${({ $variableHeight }) => ($variableHeight ? 'auto' : '36px')};
    padding: 0px 12px;
    display: flex;
    align-items: center;
    background-color: ${colourPalette.neutral0};
    color: ${colourPalette.neutral600};
    text-decoration: none;
    cursor: ${({ $isMuted }) => ($isMuted ? 'default' : 'pointer')};
    position: relative;
    border-radius: 4px;

    &:hover {
      text-decoration: none;
    }
    span {
      font-size: ${fontSize.fontSizeSM};
      color: ${({ $isMuted }) => ($isMuted ? colourPalette.neutral500 : colourPalette.neutral600)};
      opacity: ${({ $isMuted }) => ($isMuted ? '0.9' : '1')};
      display: block;
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      text-decoration: none;
      white-space: nowrap;
      line-height: ${lineHeight.lineHeightMD};
    }

    & > svg {
      position: absolute;
      top: 50%;
      right: 9px;
      transform: translateY(-50%);
    }

    &:hover {
      background-color: ${({ $isMuted }) => ($isMuted ? colourPalette.neutral0 : hexToRGBA('#F0F0F0', 0.8))};
    }

    &:active {
      background-color: ${({ $isMuted }) => ($isMuted ? colourPalette.neutral0 : hexToRGBA('#F0F0F0', 0.8))};
    }
  `,
};
