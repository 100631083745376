import styled from 'styled-components';
import { colourPalette } from 'styles/styles';
export const FiltersDetailsContentItemStyle = {
  Container: styled.div`
    display: flex;
  `,
  Icon: styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 24px;
    width: 24px;
    height: 36px;
  `,
  Content: styled.div`
    flex: 1 1 auto;
    padding-left: 10px;
    & > h4 {
      margin: 0 0 4px;
      font-size: 13px;
      line-height: 17px;
      color: ${colourPalette.neutral900};
      font-weight: 400;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      cursor: pointer;
    }
    & > p {
      margin: 0;
      font-size: 12px;
      line-height: 16px;
      color: ${colourPalette.neutral500};
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      cursor: pointer;
    }
    &.extended-title {
      & > h4 {
        -webkit-line-clamp: initial;
      }
    }
    &.extended-conditions {
      & > p {
        -webkit-line-clamp: initial;
      }
    }
  `,
};
