type Primitive = string | number | boolean | null | undefined;
type JsonObject = { [key: string]: JsonValue };
type JsonArray = JsonValue[];
type JsonValue = Primitive | JsonObject | JsonArray;

const snakeToCamel = (str: string): string => str.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());

const camelToSnake = (str: string): string => str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const convertKeysToCamel = <T extends JsonValue>(obj: T): T => {
  if (Array.isArray(obj)) {
    return obj.map(convertKeysToCamel) as T;
  } else if (obj && typeof obj === 'object') {
    return Object.keys(obj).reduce((acc, key) => {
      const camelKey = snakeToCamel(key);
      (acc as JsonObject)[camelKey] = convertKeysToCamel((obj as JsonObject)[key]);
      return acc;
    }, {} as JsonObject) as T;
  }
  return obj;
};

export const convertKeysToSnake = <T extends JsonValue>(obj: T): T => {
  if (Array.isArray(obj)) {
    return obj.map(convertKeysToSnake) as T;
  } else if (obj && typeof obj === 'object') {
    return Object.keys(obj).reduce((acc, key) => {
      const snakeKey = camelToSnake(key);
      (acc as JsonObject)[snakeKey] = convertKeysToSnake((obj as JsonObject)[key]);
      return acc;
    }, {} as JsonObject) as T;
  }
  return obj;
};
