import styled from 'styled-components';
import { InputMask } from 'primereact/inputmask';
import { fontSize, lineHeight, colourPalette, hexToRGBA } from 'styles/styles';

export const PInputMaskStyle = {
  InputMask: styled(InputMask)`
    width: 100%;
    &.p-inputtext {
      padding: 0px 12px;
      display: flex;
      align-items: center;

      min-height: 36px;
      font-size: ${fontSize.fontSizeSM};
      line-height: ${lineHeight.lineHeightMD};
      &.p-inputtext-sm {
        min-height: 32px;
      }
      &.p-inputtext-lg {
        min-height: 56px;
        font-size: 18px;
      }
      &::placeholder {
        color: ${colourPalette.neutral400};
      }

      background-color: ${colourPalette.neutral0};
      border: 1px solid ${colourPalette.neutral400};
      &:hover {
        border: 1px solid ${colourPalette.neutral500};
      }
      &.p-invalid {
        border-color: ${colourPalette.rose600};
      }
      &:focus,
      &.p-invalid:focus {
        box-shadow: 0px 0px 0px 4px ${hexToRGBA(colourPalette.blue600, 0.3)};
        border-color: ${colourPalette.blue600};
      }
    }
    &.view-only-input {
      border: 1px solid transparent;
      resize: none;
      background: transparent;
    }
  `,
};
