import styled, { css } from 'styled-components';
import { MultiSelect } from 'primereact/multiselect';
import {
  borderRadius,
  borders,
  colourPalette,
  fontSize,
  fontWeight,
  gap,
  hexToRGBA,
  lineHeight,
  padding,
  shadow,
} from 'styles/styles';
import { PCheckboxBaseStyle } from '../PCheckbox/PCheckbox.style';
import { PDropdownContainerBaseStyle } from '../PDropdown/PDropdown.style';

export const PMultiSelectGlobalStyle = css`
  .p-multiselect-panel.p-component {
    box-shadow: ${shadow.shadowMD};
    /**
      * Note: Prime React sets the panel min-width to the actual field width dinamically
      * This is to adjust the panel to be the same width of the field
      */
    width: 0;

    .p-multiselect-header {
      padding: ${padding.paddingSM} ${padding.paddingSM} ${padding.paddingSM} ${padding.paddingMD};
      border-color: ${colourPalette.neutral200};
      gap: ${gap.gap3};

      .p-multiselect-select-all {
        display: flex;
        align-items: center;

        .p-checkbox {
          margin: ${gap.gapNone};
          ${PCheckboxBaseStyle}
        }
      }

      .p-multiselect-close {
        display: none;
      }
    }

    .p-multiselect-items-wrapper {
      ::-webkit-scrollbar-track {
        border-left: ${borders.borderSM} solid ${colourPalette.neutral300};
      }
      ::-webkit-scrollbar-thumb {
        background-color: ${colourPalette.neutral300};
      }

      .p-multiselect-items {
        padding: ${padding.paddingXS};
        .p-multiselect-item {
          height: 40px;
          padding: ${padding.paddingNone} ${padding.paddingMD} ${padding.paddingNone} ${padding.paddingXS};
          border-radius: ${borderRadius.roundedSM};
          display: flex;
          align-items: center;
          outline: none;

          span {
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            color: ${colourPalette.neutral600};
            font-weight: ${fontWeight.regular};
          }

          &:focus,
          &:focus-visible,
          &:active {
            background-color: ${colourPalette.neutral0};
            box-shadow: 0px 0px 0px 2px ${hexToRGBA(colourPalette.blue600, 0.3)};
            position: relative;
            z-index: 1;
          }

          &.p-highlight {
            background-color: ${colourPalette.blue50};
            span {
              color: ${colourPalette.blue500};
            }
          }

          &:hover {
            background-color: ${colourPalette.neutral100};
            span {
              color: ${colourPalette.neutral600};
            }
          }
        }
        & > .p-multiselect-item-group {
          padding: ${padding.paddingXS};
        }
      }
    }

    .p-multiselect-checkbox {
      height: 16px;
      .p-checkbox {
        vertical-align: top;
        ${PCheckboxBaseStyle}
        margin-top: 0px;
      }
    }

    &.fixed-width {
      width: 300px !important;
    }
  }
`;

export const PMultiSelectStyle = {
  Container: styled.div`
    ${PDropdownContainerBaseStyle}
  `,
  MultiSelect: styled(MultiSelect)`
    &.p-multiselect {
      border-radius: ${borderRadius.roundedSM};
      border: ${borders.borderSM} solid ${colourPalette.gray200};
      background-color: ${colourPalette.neutral0};
      height: 40px;
      display: flex;

      .p-multiselect-label-container {
        display: flex;
        align-items: center;
        .p-multiselect-label {
          padding: 0 ${gap.gap5} 0 ${gap.gap3};
          max-height: fit-content;
          line-height: ${lineHeight.lineHeightMD};
          font-size: ${fontSize.fontSizeSM};
          font-weight: ${fontWeight.medium};
          font-family: 'SuisseIntl', sans-serif;
          color: ${colourPalette.neutral600};
          &.p-placeholder {
            color: ${colourPalette.neutral400};
            font-weight: ${fontWeight.medium};
          }
          &::placeholder {
            color: ${colourPalette.neutral400};
            font-weight: ${fontWeight.medium};
          }
        }
      }

      .p-multiselect-clear-icon {
        width: 16px;
        height: 16px;
        right: 38px;
        path {
          &[fill] {
            fill: ${colourPalette.neutral600};
          }
          &[stroke] {
            stroke: ${colourPalette.neutral600};
          }
        }
      }

      .p-multiselect-trigger {
        width: fit-content;
        padding: 0 ${gap.gap3} 0 ${gap.gap2};
        & > svg {
          width: 16px;
          height: 16px;
          path {
            &[fill] {
              fill: ${colourPalette.neutral600};
            }
            &[stroke] {
              stroke: ${colourPalette.neutral600};
            }
          }
        }
      }

      &:hover {
        border-color: ${colourPalette.gray300};
        background-color: ${colourPalette.neutral100};
      }
      &:focus,
      &.p-inputwrapper-focus,
      &:focus-within,
      &.p-invalid:focus-within {
        border-color: ${colourPalette.blue500};
        box-shadow: 0px 0px 0px 4px ${hexToRGBA(colourPalette.blue600, 0.3)};
        background-color: ${colourPalette.neutral0};
      }

      &.p-invalid {
        border-color: ${colourPalette.rose500};
        .p-multiselect-label {
          color: ${colourPalette.rose600};
          &.p-placeholder,
          &::placeholder {
            color: ${colourPalette.rose600};
          }
        }
        & + .p-dropdown-invalid-icon {
          display: block;
        }
      }

      &.p-disabled {
        opacity: 1;
        background-color: ${colourPalette.neutral100};
        border-color: ${colourPalette.gray200};
        .p-multiselect-label {
          color: ${colourPalette.neutral300} !important;
          &.p-placeholder,
          &::placeholder {
            color: ${colourPalette.neutral300} !important;
          }
        }
        .p-multiselect-trigger > svg > path {
          &[fill] {
            fill: ${colourPalette.neutral300};
          }
          &[stroke] {
            stroke: ${colourPalette.neutral300};
          }
        }
        & + .p-dropdown-invalid-icon {
          display: none;
        }
      }

      &.overlay-panel-visible {
        .p-multiselect-trigger {
          & > svg {
            transform: rotate(180deg);
          }
        }
      }

      &.has-value {
        .p-multiselect-label-container {
          padding-right: ${gap.gap2};
        }
      }

      &.p-multiselect-filled {
        background-color: ${colourPalette.neutral100};
        border: ${borders.borderSM} solid ${colourPalette.neutral100};
        &:hover {
          background-color: ${colourPalette.neutral200};
          border-color: ${colourPalette.neutral200};
        }
        &:focus,
        &.p-inputwrapper-focus,
        &:focus-within,
        &.p-invalid:focus-within {
          border-color: ${colourPalette.neutral100};
          background-color: ${colourPalette.neutral100};
        }
        &.p-invalid {
          background-color: ${colourPalette.rose50};
          border-color: ${colourPalette.rose50};
        }
        &.p-disabled {
          background-color: ${colourPalette.neutral100};
          border-color: ${colourPalette.neutral100};
        }
      }
    }
  `,
};
