import React, { useMemo } from 'react';
import { Toast, ToastMessage, ToastProps } from 'primereact/toast';
import { SnackbarStyle as S } from './Snackbar.style';
import { PButton } from 'components/prime/PButton/PButton';
import { PButtonIcon } from 'components/prime/PButton/PButtonIcon';
import { Icon } from 'components/Icon/Icon';

/**
 * @deprecated Use SnackbarV2 instead
 */
export type SnackbarMessageCustomProps = {
  severity?: ToastMessage['severity'];
  title?: string | React.ReactNode;
  label: string | React.ReactNode;
  closable?: boolean;
  action?: {
    label: string;
    onClick?: () => void;
  };
  sticky?: boolean;
  onDismiss: () => void;
};

/**
 * @deprecated Use SnackbarV2 instead
 */
export const SnackbarMessageCustom: React.FC<SnackbarMessageCustomProps> = ({
  label,
  title,
  action,
  closable,
  severity,
  sticky,
  onDismiss,
}) => {
  const icon = useMemo(() => {
    switch (severity) {
      case 'info':
        return <Icon icon="infoCircleSolid" size={16} className="icon-info" />;
      case 'success':
        return <Icon icon="checkCircleSolid" size={16} className="icon-success" />;
      case 'error':
        return <Icon icon="alertOctagonSolid" size={16} className="icon-error" />;
      case 'warn':
        return <Icon icon="alertTriangleSolid" size={16} className="icon-warning" />;
      default:
        return null;
    }
  }, [severity]);
  const actionComponent = useMemo(() => {
    return (
      <S.Actions>
        {!!action && (
          <PButton
            {...action}
            onClick={() => {
              action.onClick?.();
              onDismiss();
            }}
            size="sm"
            severity="invert"
          />
        )}
        {(sticky || closable) && <PButtonIcon onClick={onDismiss} icon="xLine" size="xs" />}
      </S.Actions>
    );
  }, [sticky, closable, action, onDismiss]);

  return (
    <S.SnackbarMessage>
      {!!icon && <div className="snackbar-message-custom-icon">{icon}</div>}
      <div className="snackbar-message-custom-content">
        {title && (
          <div className="title" data-testid="label-snackbar-title">
            {title}
          </div>
        )}
        <div className="label" data-testid="label-snackbar-description">
          {label}
        </div>
      </div>
      {!!actionComponent && (
        <div className="snackbar-message-custom-actions" data-testid="button-snackbar-action">
          {actionComponent}
        </div>
      )}
    </S.SnackbarMessage>
  );
};

/**
 * @deprecated Use SnackbarV2 instead
 */
export const Snackbar = React.forwardRef<Toast, ToastProps>((_, ref) => {
  return (
    <S.Snackbar
      appendTo={window.document.body}
      transitionOptions={{ classNames: 'snackbar-anim' } as any}
      position="bottom-center"
      ref={ref}
    />
  );
});
