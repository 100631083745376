import styled, { css } from 'styled-components';
import { InputText } from 'primereact/inputtext';
import {
  borderRadius,
  borders,
  colourPalette,
  fontSize,
  fontWeight,
  gap,
  hexToRGBA,
  lineHeight,
  padding,
} from 'styles/styles';

export const inputTextBaseStyles = css`
  border-radius: ${borderRadius.roundedSM};
  padding: 0px ${padding.paddingMD};
  background-color: ${colourPalette.neutral0};
  border: ${borders.borderSM} solid ${colourPalette.gray200};
  display: flex;
  align-items: center;
  min-height: 40px;
  font-size: ${fontSize.fontSizeSM};
  line-height: ${lineHeight.lineHeightMD};
  color: ${colourPalette.neutral600};
  font-weight: ${fontWeight.medium};
  &.p-inputtext-sm {
    min-height: 32px;
  }
  &.p-inputtext-lg {
    min-height: 56px;
    font-size: 18px;
  }
  &::placeholder {
    color: ${colourPalette.neutral400};
    font-weight: ${fontWeight.medium};
  }
  &:hover {
    border-color: ${colourPalette.gray300};
    background-color: ${colourPalette.neutral100};
  }
  &.p-invalid {
    border-color: ${colourPalette.rose500};
    color: ${colourPalette.rose600};
    padding-right: ${gap.gap8};
    &::placeholder {
      color: ${colourPalette.rose600};
    }
  }
  &:focus,
  &.p-invalid:focus {
    box-shadow: 0px 0px 0px 4px ${hexToRGBA(colourPalette.blue600, 0.3)};
    border-color: ${colourPalette.blue500};
    background-color: ${colourPalette.neutral0};
    color: ${colourPalette.neutral600};
    &::placeholder {
      color: ${colourPalette.neutral400};
    }
  }
  &.p-disabled {
    opacity: 1;
    background-color: ${colourPalette.neutral50};
    border-color: ${colourPalette.gray200};
    color: ${colourPalette.neutral300};
    &::placeholder {
      color: ${colourPalette.neutral300};
    }
  }

  &.p-inputtext-filled {
    background-color: ${colourPalette.neutral100};
    border: ${borders.borderSM} solid ${colourPalette.neutral100};
    &:hover {
      background-color: ${colourPalette.neutral200};
      border-color: ${colourPalette.neutral200};
    }
    &.p-invalid {
      background-color: ${colourPalette.rose50};
      border-color: ${colourPalette.rose50};
    }
    &:focus,
    &.p-invalid:focus {
      border-color: ${colourPalette.neutral100};
      background-color: ${colourPalette.neutral100};
    }
    &.p-disabled {
      background-color: ${colourPalette.neutral50};
      border-color: ${colourPalette.neutral50};
    }
  }

  &.p-inputtext-ghost {
    background-color: ${colourPalette.neutral0};
    border: ${borders.borderSM} solid ${colourPalette.neutral0};
    &:hover {
      background-color: ${colourPalette.neutral100};
      border-color: ${colourPalette.neutral100};
    }
    &.p-invalid {
      background-color: ${colourPalette.rose50};
      border-color: ${colourPalette.rose50};
    }
    &:focus,
    &.p-invalid:focus {
      border-color: ${colourPalette.neutral0};
      background-color: ${colourPalette.neutral0};
    }
    &.p-disabled {
      background-color: ${colourPalette.neutral50};
      border-color: ${colourPalette.neutral50};
    }
  }
`;

export const PInputTextStyle = {
  InputTextContainer: styled.div`
    width: 100%;
    position: relative;

    & > .input-text-icon-wrapper {
      pointer-events: none;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      &.left {
        left: 13px;
      }
      &.right {
        right: 13px;
      }
      & > svg {
        width: 16px;
        height: 16px;
        path {
          &[fill] {
            fill: ${colourPalette.neutral400};
          }
          &[stroke] {
            stroke: ${colourPalette.neutral400};
          }
        }
      }
    }

    &.p-input-icon-left > .p-inputtext {
      padding-left: 36px;
    }
    &.p-input-icon-right > .p-inputtext {
      padding-right: ${gap.gap6};
    }

    &.has-value > .input-text-icon-wrapper > svg path {
      &[fill] {
        fill: ${colourPalette.neutral600};
      }
      &[stroke] {
        stroke: ${colourPalette.neutral600};
      }
    }

    &.is-invalid > .input-text-icon-wrapper > svg path {
      &[fill] {
        fill: ${colourPalette.rose500};
      }
      &[stroke] {
        stroke: ${colourPalette.rose500};
      }
    }

    &.is-disabled > .input-text-icon-wrapper > svg path {
      &[fill] {
        fill: ${colourPalette.neutral300};
      }
      &[stroke] {
        stroke: ${colourPalette.neutral300};
      }
    }

    &:focus-within > .input-text-icon-wrapper > svg path {
      &[fill] {
        fill: ${colourPalette.neutral600};
      }
      &[stroke] {
        stroke: ${colourPalette.neutral600};
      }
    }

    .input-text-invalid-icon path {
      &[fill] {
        fill: ${colourPalette.rose500} !important;
      }
      &[stroke] {
        stroke: ${colourPalette.rose500} !important;
      }
    }
  `,
  InputText: styled(InputText)`
    width: 100%;
    &.p-inputtext {
      ${inputTextBaseStyles}
    }
    &.view-only-input {
      border: 1px solid transparent;
      resize: none;
      background: transparent;
    }
  `,
};
