import styled from 'styled-components';
import { cssScreens } from 'styles/styles';

export const TopBarCompanyListStyle = {
  Container: styled.div`
    margin-left: 12px;
    max-width: 170px;
    @media screen and (max-width: ${cssScreens.mobileLarge}) {
      display: none;
    }
  `,
};
