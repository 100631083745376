import styled from 'styled-components';
import { colourPalette } from 'styles/styles';
export const UploadStepStyle = {
  Container: styled.form`
    margin: 0 !important;
    display: flex;
    flex-direction: column;
    height: 100%;
  `,
  MessageBody: styled.p`
    margin: 0;
    color: ${colourPalette.neutral600};
    line-height: 24px;
    position: relative;
  `,
};
