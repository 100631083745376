import styled from 'styled-components';
import { colourPalette, fontSize, lineHeight } from 'styles/styles';

export const HeyYabbleCountStepTableRowStyle = {
  Container: styled.div`
    border-top: 1px solid ${colourPalette.neutral300};
    min-height: 40px;
    display: flex;
  `,
  CheckContainer: styled.div`
    width: 40px;
    border-right: 1px solid ${colourPalette.neutral300};
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  ItemContainer: styled.div`
    flex: 1;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    color: ${colourPalette.neutral300};
    font-size: ${fontSize.fontSizeSM};
    line-height: ${lineHeight.lineHeightMD};
    &.is-checked {
      color: ${colourPalette.neutral600};
    }
  `,
  TagBox: styled.div`
    min-width: 140px;
  `,
  WidthItem: styled.div`
    width: 96px;
    border-left: 1px solid ${colourPalette.neutral300};
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 12px;
    font-size: ${fontSize.fontSizeSM};
    line-height: ${lineHeight.lineHeightMD};
    color: ${colourPalette.neutral400};
    &.is-checked {
      color: ${colourPalette.neutral600};
      font-weight: 600;
    }
  `,
  DropdownsItem: styled.div`
    width: 400px;
    border-left: 1px solid ${colourPalette.neutral300};
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 0 12px;
  `,
};
