import { Skeleton } from 'primereact/skeleton';
import styled from 'styled-components';
import { colourPalette } from 'styles/styles';

export const PSkeletonStyle = {
  Skeleton: styled(Skeleton)`
    &.p-skeleton.p-component {
      background-color: ${colourPalette.neutral200};
    }
  `,
};
