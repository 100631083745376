import React from 'react';
import { HStack } from 'components/layouts/primitives/Stack/Stack';
import { PButton } from 'components/prime/PButton/PButton';
import { Box } from 'components/layouts/primitives/Box/Box';
import { FormField } from 'components/FormField/FormField';
import { useManageProjectPermissionsModalBLoC } from 'components/modals/ManageProjectPermissionsModal/ManageProjectPermissionsModal.bloc';
import { useObservableState } from 'observable-hooks';
import { Text } from 'components/Text/Text';
import { Avatar } from 'components/Avatar/Avatar';
import { useFormValue } from 'hooks/useFormValue';
import { PAutoComplete } from 'components/prime/PAutoComplete/PAutoComplete';

export const ManageProjectPermissionsUserSearch: React.FC = () => {
  const { form, $customerUsers, $loading, addUserPermissionToProject, getCustomerUsers } =
    useManageProjectPermissionsModalBLoC();
  const user = useFormValue(form, 'user');
  const customerUsers = useObservableState($customerUsers, []);
  const loading = useObservableState($loading);

  return (
    <FormField
      form={form}
      controller={{
        name: 'user',
      }}
      helpText="Email notification will be sent once added."
    >
      {({ field }) => (
        <HStack gap="gap2">
          <Box fit="space">
            <PAutoComplete
              {...field}
              showEmptyMessage
              emptyMessage="No user found"
              value={
                field.value && customerUsers
                  ? customerUsers.find((p) => p.id === field.value) || field.value
                  : null
              }
              completeMethod={(e) => getCustomerUsers(e.query)}
              field="name"
              suggestions={customerUsers}
              placeholder="Search by name or email..."
              delay={250}
              autoHighlight
              forceSelection
              itemTemplate={(option) => (
                <HStack align="center" gap="gap2">
                  <Box fit="content">
                    <Avatar
                      size="sm"
                      user={option}
                      color="purple600"
                      bgColor="purple500"
                      bgColorAlpha={0.08}
                    />
                  </Box>
                  <Text ellipsis color="neutral600" size="fontSizeSM" lineHeight="lineHeightMD">
                    {option.name}
                  </Text>
                </HStack>
              )}
              data-testid="input-manage-project-permissions-search"
            />
          </Box>
          <PButton
            severity="soft"
            label="Add to project"
            onClick={addUserPermissionToProject}
            disabled={!user}
            loading={loading === 'addingUser'}
            data-testid="button-manage-project-permissions-add"
          />
        </HStack>
      )}
    </FormField>
  );
};
