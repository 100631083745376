import styled from 'styled-components';
import { PMenu } from 'components/prime/PMenu/PMenu';
import { colourPalette } from 'styles/styles';

export const DropdownUserStyle = {
  Container: styled.div``,
  Toggler: styled.a<{ $isActive?: boolean }>`
    width: 64px;
    padding: 4px;
    background-color: ${({ $isActive }) => ($isActive ? colourPalette.neutral300 : colourPalette.neutral0)};
    border-radius: 64px;
    display: flex;
    align-items: center;

    svg {
      margin-left: 6px;
      transition: transform 0.3s ease-in-out;
      transform: rotate(${({ $isActive }) => ($isActive ? '180deg' : '0deg')});
    }

    &:hover {
      background-color: ${colourPalette.neutral200};
    }

    &:active {
      background-color: ${colourPalette.neutral300};
    }
  `,
  Menu: styled(PMenu)`
    min-width: 230px;
    width: fit-content;
    max-width: 700px;
  `,
};
