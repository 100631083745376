import styled from 'styled-components';
import { PDialog } from 'components/prime/PDialog/PDialog';
import { colourPalette, fontSize, lineHeight } from 'styles/styles';

export const MapStepChangeResponseModalStyle = {
  Container: styled.div``,
  Dialog: styled(PDialog)`
    width: 400px;
    .p-dialog-header {
      padding: 16px 24px !important;
      border-bottom: 1px solid ${colourPalette.neutral300};
    }
  `,
  Text: styled.p`
    font-size: ${fontSize.fontSizeSM};
    line-height: ${lineHeight.lineHeightMD};
    color: ${colourPalette.neutral600};
    margin: 0px;
    margin-bottom: 24px;
  `,
  Footer: styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
  `,
};
