import styled from 'styled-components';
import { colourPalette } from 'styles/styles';
export const QuestionsDropdownStyle = {
  Container: styled.div`
    padding: 0 16px;
    padding-bottom: 16px;
    display: flex;
    flex-direction: column;
    .p-dropdown-items-wrapper {
      max-width: 100% !important;
    }
  `,
  Text: styled.span`
    font-size: 12px;
    line-height: 12px;
    color: ${colourPalette.neutral500};
    margin-bottom: 8px;
    font-weight: 600;
    transition: all 0.2s;
    .column-ignored & {
      color: ${colourPalette.neutral400};
    }
  `,
};
