import { Divider } from 'primereact/divider';
import styled from 'styled-components';
import { colourPalette } from 'styles/styles';

export const PDividerStyle = {
  Divider: styled(Divider)`
    &::before {
      min-height: 1px;
      min-width: 1px;
      background-color: ${colourPalette.neutral300};
      border-top: none;
    }
    & > div {
      color: ${colourPalette.neutral600};
    }
    &.p-divider-vertical {
      padding-top: 0px;
      padding-bottom: 0px;
    }
  `,
};
