import styled from 'styled-components';
import { colourPalette } from 'styles/styles';
export const ImportModalLoadingStyle = {
  Container: styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `,
  Text: styled.p`
    color: ${colourPalette.neutral500};
    margin-top: -40px;
  `,
};
