import styled from 'styled-components';
import { colourPalette, fontSize, lineHeight } from 'styles/styles';

export const UploadStepSelectFileBoxConfirmStyle = {
  Container: styled.div``,
  CheckboxText: styled.p`
    font-size: ${fontSize.fontSizeSM};
    line-height: ${lineHeight.lineHeightMD};
    font-weight: 600;
    color: ${colourPalette.neutral600};
    margin: 0;
  `,
};
