import styled, { css } from 'styled-components';
import { Menu } from 'primereact/menu';
import { colourPalette, hexToRGBA, shadow } from 'styles/styles';

export const MenuBaseStyle = css`
  padding: 4px 0;
  background: transparent;
  box-shadow: none;
  border-radius: 0;
  border: none;
  width: fit-content;
  min-width: 160px;
  max-width: 240px;
  .p-menu-list,
  .p-submenu-list,
  & > ul[role='menubar'] {
    background: ${colourPalette.neutral0};
    padding: 4px 0;
    border: 1px solid ${hexToRGBA(colourPalette.neutral900, 0.08)};
    box-shadow: ${shadow.shadowDefault};
    border-radius: 8px;
  }
  .p-submenu-header {
    padding: 4px 16px;
    color: ${colourPalette.neutral400};
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-transform: capitalize;
  }
  .p-menuitem {
    padding: 0 4px;
    .p-menuitem-content {
      background: none !important;
    }
    .p-menuitem-link {
      padding: 8px 8px 8px 12px;
      border-radius: 4px;
      gap: 4px;
      svg {
        flex: 0 0 24px;
        path {
          &[fill] {
            fill: ${colourPalette.neutral600};
          }
          &[stroke] {
            stroke: ${colourPalette.neutral600};
          }
        }
      }
      .p-menuitem-text {
        flex: 1 1 auto;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        line-height: 24px;
        color: ${colourPalette.neutral600};
      }
      &:focus {
        background: transparent;
        box-shadow: inset 0px 0px 0px 2px ${colourPalette.blue500};
      }
      &:hover {
        background: rgba(240, 240, 240, 0.8);
        svg {
          path {
            &[fill] {
              fill: ${colourPalette.neutral900};
            }
            &[stroke] {
              stroke: ${colourPalette.neutral900};
            }
          }
        }
        .p-menuitem-text {
          color: ${colourPalette.neutral900};
        }
      }
      &.p-disabled {
        opacity: 1;
        svg {
          path {
            &[fill] {
              fill: ${colourPalette.neutral400};
            }
            &[stroke] {
              stroke: ${colourPalette.neutral400};
            }
          }
        }
        .p-menuitem-text {
          color: ${colourPalette.neutral400};
        }
        &:focus {
          background: transparent;
          box-shadow: none;
        }
      }
    }
    &.destructive {
      .p-menuitem-link {
        svg {
          path {
            &[fill] {
              fill: ${colourPalette.rose600};
            }
            &[stroke] {
              stroke: ${colourPalette.rose600};
            }
          }
        }
        .p-menuitem-text {
          color: ${colourPalette.rose600};
        }
        &:focus {
          box-shadow: inset 0px 0px 0px 2px ${colourPalette.rose600};
        }
        &:hover {
          background: ${hexToRGBA(colourPalette.rose500, 0.1)};
          svg {
            path {
              &[fill] {
                fill: ${colourPalette.rose600};
              }
              &[stroke] {
                stroke: ${colourPalette.rose600};
              }
            }
          }
          .p-menuitem-text {
            color: ${colourPalette.rose600};
          }
        }
        &.p-disabled {
          svg {
            path {
              &[fill] {
                fill: ${colourPalette.neutral100};
              }
              &[stroke] {
                stroke: ${colourPalette.neutral100};
              }
            }
          }
          .p-menuitem-text {
            color: ${colourPalette.neutral100};
          }
        }
      }
    }
    &.footer {
      padding: 0px;
      .p-menuitem-link {
        padding: 4px 8px 4px 16px;
        flex-direction: row-reverse;
        gap: 8px;
        svg {
          path {
            &[fill] {
              fill: ${colourPalette.neutral500};
            }
            &[stroke] {
              stroke: ${colourPalette.neutral500};
            }
          }
        }
        .p-menuitem-text {
          color: ${colourPalette.neutral500};
        }
        &:focus {
          box-shadow: none;
        }
        &:hover {
          background: transparent;
        }
      }
    }
  }
  .p-menu-separator {
    margin: 4px 0;
    border-top: 1px solid ${hexToRGBA(colourPalette.neutral900, 0.08)};
  }
  &.p-menu-overlay,
  &.p-tieredmenu-overlay {
    box-shadow: none;
    border: none;
    background: transparent;
  }
`;

export const PMenuStyle = {
  Menu: styled(Menu)<{ $width?: number; $maxHeight?: number }>`
    ${MenuBaseStyle}
    ${({ $width }) =>
      typeof $width === 'number'
        ? css`
            width: ${$width}px;
          `
        : ''}
    ${({ $maxHeight }) =>
      typeof $maxHeight === 'number'
        ? css`
            .p-menu-list,
            .p-submenu-list,
            & > ul[role='menubar'] {
              max-height: ${$maxHeight}px;
              overflow-y: overlay;
            }
          `
        : ''}
  `,
};
