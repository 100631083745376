import React from 'react';
import { PCheckbox } from 'components/prime/PCheckbox/PCheckbox';
import { useObservableState } from 'observable-hooks';
import { useHeyYabbleCountStepBLoC } from '../../../../HeyYabbleCountStep.bloc';
import { HeyYabbleCountStepTableHeaderStyle as S } from './HeyYabbleCountStepTableHeader.style';
import { HStack } from 'components/layouts/primitives/Stack/Stack';
import { Text } from 'components/Text/Text';
import { PInputSwitch } from 'components/prime/PInputSwitch/PInputSwitch';
import { getUserStore } from 'stores/user.store';

export const HeyYabbleCountStepTableHeader: React.FC = () => {
  const {
    $isSelectedAll,
    $hasTcPipelineSelection,
    $filteredColumn,
    $useLegacyCodeframes,
    handleChangeMainCheckbox,
    handleUseLegacyCodeframes,
  } = useHeyYabbleCountStepBLoC();
  const useLegacyCodeframes = useObservableState($useLegacyCodeframes);
  const isSelectedAll = useObservableState($isSelectedAll, false);
  const hasTcPipelineSelection = useObservableState($hasTcPipelineSelection);
  const filteredColumn = useObservableState($filteredColumn, []);
  const user = useObservableState(getUserStore().$user);

  return (
    <S.Container>
      <S.CheckContainer>
        <PCheckbox
          checked={isSelectedAll}
          onChange={handleChangeMainCheckbox}
          disabled={!filteredColumn.length}
        />
      </S.CheckContainer>
      <S.ItemContainer>COLUMN NAME</S.ItemContainer>
      <S.WidthItem>CREDITS</S.WidthItem>
      {hasTcPipelineSelection && (
        <S.DropdownsItem>
          <HStack stretch align="center" justify="space-between">
            <Text>{!!user?.isYabbleOwner && 'PIPELINE AND '}CODEFRAME</Text>
            {user?.isYabbleOwner && (
              <PInputSwitch
                checked={useLegacyCodeframes}
                label="Legacy codeframes"
                onChange={(e) => handleUseLegacyCodeframes(!!e.target.value)}
              />
            )}
          </HStack>
        </S.DropdownsItem>
      )}
    </S.Container>
  );
};
