import styled from 'styled-components';
import { colourPalette, fontSize, lineHeight } from 'styles/styles';

export const CreditsModalTopupFormBalanceAfterStyle = {
  Container: styled.div`
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    svg {
      path {
        stroke: ${colourPalette.neutral500};
      }
    }
  `,
  Text: styled.span`
    font-size: ${fontSize.fontSizeSM};
    font-weight: 400;
    line-height: ${lineHeight.lineHeightMD};
    color: ${colourPalette.neutral500};
    margin: 0;
  `,
};
