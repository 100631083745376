import styled, { css } from 'styled-components';
import { fontSize, fontWeight, lineHeight, colourPalette } from 'styles/styles';

export const TextStyle = {
  Component: styled.span<{ $props: any }>`
    ${({ $props }) => css`
      margin: 0;
      font-size: ${$props.size ? (fontSize as any)[$props.size] : 'inherit'};
      line-height: ${$props.lineHeight ? (lineHeight as any)[$props.lineHeight] : 'inherit'};
      font-weight: ${$props.weight ? (fontWeight as any)[$props.weight] : 'inherit'};
      color: ${$props.color ? (colourPalette as any)[$props.color] : 'inherit'};
      text-align: ${$props.align || 'left'};

      text-underline-position: under;
      ${$props.textDecoration === 'underline-solid'
        ? css`
            text-decoration: underline;
            text-decoration-style: solid;
          `
        : $props.textDecoration === 'underline-dotted'
        ? css`
            text-decoration: underline;
            text-decoration-style: dotted;
          `
        : ''}
      ${$props.transform
        ? $props.transform === 'upper-first-letter'
          ? css`
              display: block;
              &::first-letter {
                text-transform: uppercase;
              }
            `
          : css`
              text-transform: ${$props.transform};
            `
        : ''}
      ${$props.ellipsis
        ? css`
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: ${typeof $props.ellipsis === 'number' ? Math.abs($props.ellipsis) : 1};
            display: -webkit-box;
            -webkit-box-orient: vertical;
          `
        : ''}
      ${$props.fit ? `flex: ${$props.fit === 'content' ? '0 0 fit-content' : '1 1 auto'};` : ''}
      ${$props.wordBreak ? `word-break: ${$props.wordBreak}` : ''}
    `}
  `,
};
